import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/_services/auth.service';
import { UiService } from 'src/app/_services/ui.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loading = false;
  hide = true;
  disableLoginBtn = false;
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private auth: AuthService,
    private ui: UiService,
    private translate: TranslateService
  ) {
    this.form = fb.group({
      username: [null, Validators.required],
      password: [null, Validators.required],
    });
  }

  ngOnInit() {}

  login(): void {
    this.loading = true;
    this.auth.user = null;
    this.disableLoginBtn = true;
    let login$ = this.auth
      .login(this.form.value.username, this.form.value.password)
      .subscribe(
        (res: any) => {
          this.loading = false;

          if (res && res.data.loginUser) {
            this.auth.storeUser(res.data?.loginUser);

            setTimeout(() => {
              if (this.auth.hasPermission('appointment', 'read'))
                this.router.navigate(['pages', 'appointments']);
              else this.router.navigate(['pages', 'schedule-time-tables']);

              this.disableLoginBtn = false;

              console.log('login');
            }, 1500);
          }
          login$?.unsubscribe();
        },
        (err) => {
          this.ui.openSnackBarError(this.translate.instant(err.message));
          login$?.unsubscribe();
          this.disableLoginBtn = false;
        }
      );
  }
}
