<div [matMenuTriggerFor]="menu" class="switcher">
   <strong>
      {{auth.user?.title}} {{ auth.user?.lastName}} {{ auth.user?.firstName}}
      <small>
         <span>{{ user?.roleNames?.[0]+'' | translate }}</span></small>
   </strong>

   <mat-icon>person_outline</mat-icon>
</div>
<mat-menu #menu="matMenu">
   <button *ngIf="canSwitch" mat-menu-item [matMenuTriggerFor]="companySwitch">
      <mat-icon>swap_horizontaly</mat-icon>
      {{"SwitchToCompany"|translate}}
   </button>
   <button mat-menu-item (click)="navigateToUserProfile()">
      <mat-icon>person_outline</mat-icon>
      {{'Profile' | translate}}
   </button>
   <button mat-menu-item (click)="changePassword()">
      <mat-icon>edit</mat-icon>
      <span>{{"ChangePassword" | translate}}</span>
   </button>
   <mat-divider></mat-divider>
   <button mat-menu-item (click)="logout()" routerLinkActive="router-link-active">
      <mat-icon>logout</mat-icon>
      {{'Logout' | translate}}
   </button>
</mat-menu>

<mat-menu #companySwitch="matMenu">
   <button mat-menu-item *ngFor="let company of $companies|async" (click)="switchToCompany(company?.id)">
      <mat-icon>business</mat-icon>
      <strong *ngIf="company?.id === (user)?.company?.id">{{company?.name}}</strong>
      <span *ngIf="company?.id !== (user)?.company?.id">{{company?.name}}</span>
   </button>
</mat-menu>