import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UiService } from '../_services/ui.service';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
   constructor(
      private router: Router,
      public ui: UiService
   ) { }

   intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(request).pipe(
         catchError((error: HttpErrorResponse) => {
            let errorMsg = '';
            if (error.error instanceof ErrorEvent) {
               console.log('Client side error!');
               errorMsg = `Error: ${error.error.message}`;
            } else {
               console.log('Server side error!');
               errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
            }
            console.log('msg', errorMsg);
            console.log('http error', error);
            console.log('no connection', window.navigator.onLine);
            return throwError(() => new Error('Interceptor error'));
         })
      )
   }
}
