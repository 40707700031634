import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscribable, Unsubscribable } from 'rxjs';
import { UnitService } from 'src/app/pages/unit/unit.service';
import { CompaniesService } from 'src/app/registers/companies/companies.service';
import { Company, User } from 'src/app/_graphql/schema';
import { AuthService } from 'src/app/_services/auth.service';
import { HelperService } from 'src/app/_services/helper.service';
import { UiService } from 'src/app/_services/ui.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit, OnDestroy {

  $companies!: Subscribable<Company[]>;
  userUnsub$!: Unsubscribable;
  user?: User;
  canSwitch = false;

  constructor(
    public auth: AuthService,
    private router: Router,
    private ui: UiService,
    public companyService: CompaniesService,
    public translateService: TranslateService,
    public helperService: HelperService,
    public unitservice: UnitService,
  ) { }

  ngOnInit(): void {
    this.companyService.queryParams.take = 15000;
    this.$companies = this.companyService.all();
    this.user = this.auth.user;
    this.canSwitch = this.auth.hasPermission('company', 'switch');
    this.userUnsub$ = this.auth.user$.subscribe({
      next: (res: User) => {
        this.user = res;
      },
      error: err => this.ui.openSnackBarError(this.translateService.instant(err.message))

    });
  }

  switchToCompany(companyId: any) {
    if (companyId == this.auth.user?.companyId) {
      this.ui.openSnackBarWarning(this.translateService.instant("AlreadyLogedinToThisCompany"));
      return;
    }
    let unsub$ = this.companyService.switchCompany(companyId).subscribe({
      next: (res: any) => {
        this.auth.originalUser = this.auth.user;
        this.auth.user = res;
        this.auth.clearCache();
        this.unitservice.fetchMoreData();
        this.helperService.refetchAfterSwitch = true;
        unsub$.unsubscribe();
      },
      error: err => this.ui.openSnackBarError(this.translateService.instant(err.message))
    })
  }

  logout() {
    this.auth.logout();
  }

  navigateToUserProfile() {
    if (this.auth.hasPermission('Appointment', 'overview'))
      this.router.navigate(['registers', 'users', this.auth.user.id, 'pages', 'appointments']);
    else if (this.auth.hasPermission('Appointment', 'schedule-time-table'))
      this.router.navigate(['registers', 'users', this.auth.user.id, 'pages', 'appointments']);
  }

  changePassword() {
    this.ui.openSchedulerDialog();
  }

  ngOnDestroy(): void {
    this.userUnsub$?.unsubscribe();
  }

}
