import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginComponent } from './login/login.component';
import { MaterialModule } from '../material.module';
import { WrappersModule } from '../wrappers/wrappers.module';
import { TranslateModule } from '@ngx-translate/core';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { VerifyComponent } from './verify/verify.component';
import { ComponentsModule } from '../components/components.module';
import { ChangePasswordComponent } from './change-password/change-password.component';


@NgModule({
  declarations: [LoginComponent, ForgotPasswordComponent, ResetPasswordComponent, VerifyComponent, ChangePasswordComponent],
  imports: [
    CommonModule,
    WrappersModule,
    MaterialModule,
    TranslateModule,
    ComponentsModule
  ],
  exports: [LoginComponent]
})
export class RegisterModule { }
