<div fxLayout="column" class="content-p" fxLayoutAlign="center center">
   <h2 md-dialog-title>{{'change_password_title' | translate}}</h2>
   <form [formGroup]="form" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="8px"
      (submit)="changePassword()">
      <mat-form-field>
         <mat-label>{{'OldPassword'|translate}}</mat-label>
         <input matInput type="password" formControlName="oldPassword" minlength="4" [type]="hide ? 'password' : 'text'"
            (keyup.enter)="changePassword()" />
         <button mat-icon-button matSuffix type="button" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
            <mat-icon (click)="hide = !hide">{{ hide ? 'visibility_off' : 'visibility'}}</mat-icon>
         </button>
      </mat-form-field>
      <mat-form-field>
         <mat-label>{{'NewPassword'|translate}}</mat-label>
         <input matInput type="password" formControlName="password" minlength="4" [type]="hide ? 'password' : 'text'"
            (keyup.enter)="changePassword()" />
         <button mat-icon-button matSuffix type="button" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
            <mat-icon (click)="hide = !hide">{{ hide ? 'visibility_off' : 'visibility'}}</mat-icon>
         </button>
      </mat-form-field>
   </form>
   <div fxFlex>
      <button mat-raised-button [disabled]="form.invalid" color="primary" (click)="changePassword();" cdkFocusInitial>{{
         "Save" | translate }}</button>
   </div>
</div>