import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { first, Unsubscribable } from 'rxjs';
import { UserService } from 'src/app/registers/user/user.service';
import { UiService } from 'src/app/_services/ui.service';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnDestroy {
  route$: any;
  hide: boolean = false;

  form: FormGroup = this.fb.group({
    email: [{ value: null, disable: true }, [Validators.required]],
    token: [null, [Validators.required]],
    password: [null, [Validators.required]]
  });

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private ui: UiService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.form.get('email')?.disable();
    this.form.get('token')?.disable();
    this.route$ = this.route.params.subscribe(p => {
      this.form.patchValue({
        email: p['email'],
        token: p['verificationCode']
      });
    });
  }

  verify() {
    this.form.disable();
    this.userService.acceptInvitation(this.form.value).pipe(first()).subscribe({
      next: () => {
        this.ui.openSnackBar(this.translate.instant('SuccesfullyChangedPAssword'));
        this.router.navigate(['/']);
      },
      error: err => this.ui.openSnackBar(this.translate.instant(err.message))
    })
  }

  ngOnDestroy(): void {
    this.route$?.unsubscribe();
  }

}
