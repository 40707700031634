<app-public-wrapper>
   <div fxLayout="column" class="content-p" fxLayoutAlign="center center">
      <h2 md-dialog-title>{{'rest_password_title' | translate}}</h2>
      <form [formGroup]="form" fxLayout="column" fxLayoutAlign="center" fxLayoutGap="8px" (submit)="resetPassword()">
         <mat-form-field fxFlex>
            <mat-label>{{ 'Email'|translate}}</mat-label>
            <input matInput type="email" formControlName="username" />
         </mat-form-field>
         <mat-form-field>
            <mat-label>{{'VerificationCode'|translate}}</mat-label>
            <input matInput formControlName="verificationCode" minlength=" 4" />
         </mat-form-field>
         <mat-form-field>
            <mat-label>{{'NewPassword'|translate}}</mat-label>
            <input matInput type="password" formControlName="newPassword" minlength="4"
               [type]="hide ? 'password' : 'text'" (keyup.enter)="resetPassword()" />
            <button mat-icon-button matSuffix type="button" [attr.aria-label]="'Hide password'"
               [attr.aria-pressed]="hide">
               <mat-icon (click)="hide = !hide">{{ hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
         </mat-form-field>
         <app-password-strength [hasNumber]="true" [hasUpper]="true" [hasLower]="true"
            infoMessage="{{'Lozinka treba da sadrzi izmedju 6 i 30karaktera, broj, mala i velika slova'|translate}}"
            [userPassword]="this.form.get('newPassword')"></app-password-strength>
         <mat-form-field>
            <mat-label>{{'RepeatPassword'|translate}}</mat-label>
            <input matInput type="password" [formControl]="repeatPassword" minlength="4"
               [type]="hide ? 'password' : 'text'" (keyup.enter)="resetPassword()" />
            <button mat-icon-button matSuffix type="button" [attr.aria-label]="'Hide password'"
               [attr.aria-pressed]="hide">
               <mat-icon (click)="hide = !hide">{{ hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
         </mat-form-field>

      </form>
      <div fxLayout="row" fxFlex>
         <button mat-raised-button [disabled]="form.invalid || repeatPassword.invalid" color="primary"
            (click)="resetPassword();" cdkFocusInitial>{{ "Save" | translate }}</button>
      </div>
   </div>

</app-public-wrapper>