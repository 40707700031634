import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable, map, of } from 'rxjs';
import {
  Maybe,
  PageRequestInput,
  RoleName,
  User,
  UserType,
} from 'src/app/_graphql/schema';
import { BaseService } from 'src/app/_services/base.service';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService<User> {
  protected override selectOneFields = gql`
    fragment SelectOneFields on User {
      id
      firstName
      lastName
      avatarId
      email
      isLocked
      title
      languageId
      roleNames
      companyId
      type
      deleted
    }
  `;

  protected override selectAllFields = gql`
    fragment SelectAllFields on User {
      id
      firstName
      lastName
      email
      type
      languageId
      isLocked
      initiatedCount
      scheduledCount
      deleted
      company {
        shortName
      }
    }
  `;

  private changePasswordMutation = gql`
    mutation changePassword($oldPassword: String!, $password: String!) {
      changePassword(oldPassword: $oldPassword, password: $password)
    }
  `;

  private resendInviteEndUserMutation = gql`
    mutation ResendInvite($userId: ID!) {
      resendInvite(userId: $userId) {
        id
        emailConfirmed
      }
    }
  `;

  private lockoutUserMutation = gql`
    mutation lockUser($userId: ID!, $lock: Boolean!) {
      lockUser(userId: $userId, lock: $lock) {
        id
        isLocked
      }
    }
  `;

  private verifyEndUserMutation = gql`
    mutation verifyEndUser($email: String!, $verificationCode: String!) {
      verifyEndUser(email: $email, verificationCode: $verificationCode) {
        id
        emailConfirmed
      }
    }
  `;

  private acceptInvitationMutation = gql`
    mutation acceptInvitation(
      $email: String!
      $token: String!
      $password: String!
    ) {
      acceptInvitation(email: $email, token: $token, password: $password) {
        id
        emailConfirmed
      }
    }
  `;

  private requestResetPasswordUserMutation = gql`
    mutation requestResetPasswordUser($username: String!) {
      requestResetPasswordUser(username: $username)
    }
  `;

  private resetPasswordUserMutation = gql`
    mutation resetPasswordUser(
      $username: String!
      $verificationCode: String!
      $newPassword: String!
    ) {
      resetPasswordUser(
        username: $username
        verificationCode: $verificationCode
        newPassword: $newPassword
      )
    }
  `;

  private setUserRolesMutation = gql`
    mutation setUserRoles($userId: ID!, $roles: [RoleName]!) {
      setUserRoles(userId: $userId, roles: $roles) {
        id
        firstName
        lastName
        title
        type
      }
    }
  `;

  private existingUserMutation = gql`
    mutation existingUser($email: String!) {
      existingUser(email: $email)
    }
  `;

  private getFromUnitQuery = gql`
    query getFromUnit(
      $companyId: ID
      $unitId: ID
      $userType: [UserType]
      $pageRequestInput: PageRequestInput
    ) {
      getFromUnit(
        companyId: $companyId
        unitId: $unitId
        userType: $userType
        pageRequestInput: $pageRequestInput
      ) {
        data {
          ...SelectAllFields
        }
        totalCount
      }
    }
    ${this.selectAllFields}
  `;

  constructor(apollo: Apollo) {
    super(apollo);
    this.initGql('user');
  }

  public changePassword(data: any) {
    return this.mutation(this.changePasswordMutation, data);
  }

  public resendVerificationEndUser(userId: string) {
    return this.mutation(this.resendInviteEndUserMutation, { userId });
  }

  public lockoutUser(data: { userId: string; lock: boolean }) {
    return this.mutation(this.lockoutUserMutation, data);
  }

  public verifyEndUser(data: { email: string; verificationCode: string }) {
    return this.mutation(this.verifyEndUserMutation, data);
  }

  public acceptInvitation(data: {
    email: string;
    token: string;
    password: string;
  }) {
    return this.mutation(this.acceptInvitationMutation, data);
  }

  public requestResetPasswordUser(username: string) {
    return this.mutation(this.requestResetPasswordUserMutation, username);
  }

  public resetPasswordUser(data: {
    username: string;
    verificationCode: string;
    newPassword: string;
  }) {
    return this.mutation(this.resetPasswordUserMutation, data);
  }

  public setUserRoles(data: { usrId: string; roles: RoleName[] }) {
    return this.mutation(this.setUserRolesMutation, data);
  }

  public existingUser(email: string) {
    return this.mutation(this.existingUserMutation, email);
  }

  public filterInItem(data: any) {
    return this.query(this.getFromUnitQuery, data);
  }

  _watchQ: any;
  public filterUsers(data: any): Observable<User[]> {
    this._watchQ = this.apollo.watchQuery({
      query: this.getFromUnitQuery,
      fetchPolicy: 'cache-first',
      variables: { ...data },
    });
    this.addRefetchQuery(this.getFromUnitQuery, {
      queryParams: this.queryParams,
    });
    return this._watchQ.valueChanges.pipe(
      map((result: any) => {
        if (!result || !result.data) return null;

        const keys = Object.keys(result.data);
        if (result.data && keys.length) {
          return result.data[keys[0]];
        }

        return of(null);
      })
    );
  }
}
