import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { DocumentNode } from 'graphql';
import { catchError, first, map, of, Subscribable, take } from 'rxjs';
import { PageRequestInput } from '../_graphql/schema';

@Injectable({
  providedIn: 'root'
})
export class BaseService<ReturnT = any, ImportT = any> {

  protected selectOneFields!: DocumentNode;
  protected selectAllFields!: DocumentNode;
  protected selectOneQuery!: DocumentNode;
  protected selectQuery!: DocumentNode;
  protected createMutation!: DocumentNode;
  protected modifyMutation!: DocumentNode;
  protected deleteMutation!: DocumentNode;
  protected restoreMutation!: DocumentNode;
  protected publishMutation!: DocumentNode;

  private pageIndex: number = 0;
  private loading: boolean = false;
  private totalCount: number = 0;
  private oneWQ: Subscribable<ReturnT>[] = [];
  public allWQ: any[] = [];
  private refetchAdditionalQueries: any[] = [];
  _watchQuery!: any;

  public queryParams: PageRequestInput = {
    showDeleted: false,
    query: '',
    sortBy: '',
    sortOrder: '',
    skip: 0,
    take: 25,
  };

  private nodeName!: string;
  private nodeNamePlural!: string;

  constructor(protected apollo: Apollo) { }

  protected initGql(nodeName: string, nodeNamePlural: string | null = null) {
    this.nodeName = nodeName;
    this.nodeNamePlural = nodeNamePlural ? nodeNamePlural : `${nodeName}s`;
    this.createQueries();
  }

  public get isLoading(): boolean {
    return this.isLoading;
  }

  public isInitialized(): boolean {
    if (this.allWQ[<any>this.nodeNamePlural.toString()])
      return true;
    return false;
  }

  public isQueryInitialized(): boolean {
    return this._watchQuery ? true : false;
  }

  protected createQueries() {
    this.selectOneQuery = gql`
    query ${this.nodeName}($id: ID!) {
      ${this.nodeName}(id: $id) {
        ...SelectOneFields
      }
    }
    ${this.selectOneFields}
    `;

    this.selectQuery = gql`
      query ${this.nodeNamePlural}($queryParams: PageRequestInput) {
        ${this.nodeNamePlural}(pageRequestInput: $queryParams) {
          data {
          ...SelectAllFields
          }
          totalCount
        }
      }
      ${this.selectAllFields}
      `;

    this.createMutation = gql`
    mutation add${this.camelNodeName()}($item: ${this.camelNodeName()}Input!) {
      create${this.camelNodeName()}(item: $item)
      {
        ...SelectOneFields 
      }
    }
    ${this.selectOneFields}
    `;

    this.modifyMutation = gql`
    mutation edit${this.camelNodeName()}($item: ${this.camelNodeName()}Input! ) {
      update${this.camelNodeName()}(item: $item)
      {
        ...SelectOneFields
      }
    }
    ${this.selectOneFields}
    `;

    this.deleteMutation = gql`
    mutation delete${this.camelNodeName()}($id: ID!) {
      delete${this.camelNodeName()}(id: $id) 
      {
        id
        deleted
      }
    }
    `;

    this.restoreMutation = gql`
    mutation restore${this.camelNodeName()}($id: ID!) {
      restore${this.camelNodeName()}(id: $id)
      {
        ...SelectOneFields
      }
    }
    ${this.selectOneFields}
    `;

    this.publishMutation = gql`
    mutation publish${this.camelNodeName()}($id: ID!) {
      publish${this.camelNodeName()}(id: $id)
      {
        ...SelectOneFields
      }
    }
    ${this.selectOneFields}
    `;
  }

  public one(id: string | any, useCache = true): Subscribable<ReturnT> {
    this.loading = true;
    if (!this.oneWQ[id]) {
      console.log('Main subscriber  created for ONE ' + this.nodeName + ' ' + id)
      this.oneWQ[id] = this.apollo.watchQuery({
        query: this.selectOneQuery,
        variables: { id },
        fetchPolicy: useCache ? 'cache-first' : 'network-only'
      }).valueChanges.pipe<ReturnT>(
        map<any, ReturnT>((result: any) => {
          this.loading = false;
          if (!result || !result.data)
            return null;

          const keys = Object.keys(result.data);
          if (result.data && keys.length) {
            return result.data[keys[0]];
          }

          return null;
        })
      );
    } else {
      console.log('Copy returned for for ONE ' + this.nodeName + ' ' + id)
    }

    return this.oneWQ[id];
  }

  public all(filters: any = null, params: any = {}, useCache = true, slot: any = null): Subscribable<ReturnT[]> {
    this.loading = true;
    var key = slot || this.getCacheKey(filters);
    if (!this.allWQ[key]) {
      this.allWQ[key] = this.apollo.watchQuery({
        query: this.selectQuery,
        fetchPolicy: useCache ? 'cache-first' : 'network-only',
        variables: { queryParams: { ...this.queryParams } }
      });
      this.addRefetchQuery(this.selectQuery, { queryParams: this.queryParams })
    }
    return this.allWQ[key].valueChanges.pipe(
      map((result: any) => {
        this.loading = false;
        if (!result || !result.data)
          return null;

        const keys = Object.keys(result.data);
        if (result.data && keys.length) {
          this.totalCount = result.data[keys[0]].totalCount || 0;
          return result.data[keys[0]].data;
        }

        return of(null);
      }),
      // catchError(error => {
      //   // console.log('ERROR on baseService.all()', error);
      //   return of(null);
      // }),
    );
  }

  public query(query: DocumentNode, data: any = null, useCache = true): Subscribable<ReturnT[]> {
    this._watchQuery = this.apollo.watchQuery({
      query: query,
      fetchPolicy: useCache ? 'cache-first' : 'network-only',
      variables: data
    });
    return this._watchQuery.valueChanges.pipe(
      map((result: any) => {
        if (!result || !result.data)
          return null;

        const keys = Object.keys(result.data);
        if (result.data && keys.length) {
          return result.data[keys[0]];
        }

        return null;
      },
        // catchError(error => {
        //   console.log(error);
        //   return of(null);
        // })
      ),
    );
  }

  public create(data: any): Subscribable<ReturnT> {
    return this.apollo.mutate<ImportT>({
      mutation: this.createMutation,
      refetchQueries: [
        { query: this.selectQuery, variables: { queryParams: this.queryParams } },
        ...this.refetchAdditionalQueries
      ],
      variables: data
    }).pipe(take(1)).pipe(
      map((result: any) => {
        if (!result || !result.data)
          return null;

        const keys = Object.keys(result.data);
        if (result.data && keys.length) {
          return result.data[keys[0]];
        }

        return null;
      },
        // catchError(error => {
        //   console.log(error);
        //   return of(null);
        // })
      )
    );
  }

  public modify(data: ImportT): Subscribable<ReturnT> {
    return this.apollo.mutate({
      mutation: this.modifyMutation,
      refetchQueries: [

        ...this.refetchAdditionalQueries
      ],
      variables: data
    }).pipe(take(1)).pipe(
      first(),
      map((result: any) => {
        if (!result || !result.data)
          return null;

        const keys = Object.keys(result.data);
        if (result.data && keys.length) {
          return result.data[keys[0]];
        }

        return null;
      },
        // catchError(error => {
        //   console.log(error);
        //   return of(null);
        // })
      )
    );
  }

  public delete(data: any): Subscribable<ReturnT> {
    return this.apollo.mutate({
      mutation: this.deleteMutation,
      refetchQueries: [
        ...this.refetchAdditionalQueries
      ],
      variables: { id: data.id }
    }).pipe(take(1)).pipe(
      first(),
      map((result: any) => {
        if (!result || !result.data)
          return null;

        const keys = Object.keys(result.data);
        if (result.data && keys.length) {
          return result.data[keys[0]];
        }

        return null;
      },
        // catchError(error => {
        //   console.log(error);
        //   return of(null);
        // })
      )
    );
  }

  public restore(data: any) {
    return this.apollo.mutate({
      mutation: this.restoreMutation,
      refetchQueries: [
        { query: this.selectQuery, variables: { queryParams: this.queryParams } },
        { query: this.selectOneQuery, variables: { id: data.id } },
        ...this.refetchAdditionalQueries
      ],
      variables: { id: data.id }
    });
  }

  protected mutation(mutation: DocumentNode, data: any, update: any = null) {
    return this.apollo.mutate({
      mutation: mutation,
      refetchQueries: this.refetchAdditionalQueries,
      variables: data,
      update: update
    }).pipe(take(1)).pipe(
      map((result: any) => {
        if (!result || !result.data)
          return null;

        const keys = Object.keys(result.data);
        if (result.data && keys.length) {
          return result.data[keys[0]];
        }
        return null;
      }),
      // catchError(error => {
      //   console.log(error);
      //   return of(null);
      // })
    );
  }

  public showDeleted(show: boolean = true) {
    this.queryParams.showDeleted = show;
    this.queryParams.skip = 0;
    this.fetchMoreData();
  }

  public applyPager(pageIndex: number, pageSize: number) {
    this.pageIndex = pageIndex;
    this.queryParams.take = pageSize;
    this.queryParams.skip = pageIndex * pageSize;
    this.fetchMoreData();
  }

  public fetchMoreData(slot: string | any = null) {
    if (this.allWQ[this.nodeNamePlural as any])
      this.allWQ[this.nodeNamePlural as any].fetchMore({
        variables: { queryParams: this.queryParams }
      });
    else
      console.log("Query is not initialized!");
  }

  public refetchQuery(data: any = {}, queryParams: PageRequestInput = {}) {
    let variables;

    if (Object.keys(data)?.length)
      variables = { ...data };
    if (Object.keys(queryParams)?.length)
      variables.queryParams = { ...queryParams };
    return this._watchQuery.refetch(variables);
  }

  public clearStore() {
    this.apollo.client.clearStore();
  }

  public resetStore() {
    this.apollo.client.resetStore();
  }

  private camelNodeName() {
    if (!this.nodeName || this.nodeName.length == 0)
      return '';

    return this.nodeName.charAt(0).toUpperCase() + this.nodeName.slice(1);
  }

  private getCacheKey(filters: any) {
    var val = this.nodeNamePlural;
    return val;
  }

  public addRefetchQuery(query: DocumentNode, variables: any = null) {
    this.refetchAdditionalQueries.push(
      { query, variables }
    );
  }


}
