import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Apollo, gql } from "apollo-angular";
import { Subject } from "rxjs";
import { RoleName, User } from "../_graphql/schema";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private static _user: any;
  private _originalUser: any;
  private authToken!: string | null;
  public service!: string | null;
  public user$!: Subject<User>;
  public originalUser$!: Subject<any>;

  constructor(private router: Router, protected apollo: Apollo) {
    this.user$ = new Subject<User>();
    this.originalUser$ = new Subject<any>();
    const storedUser = JSON.parse(localStorage.getItem("currentUser") || "{}");

    if (!AuthService._user && storedUser && storedUser.user) {
      AuthService._user = { ...storedUser.user };
      this.user$.next(AuthService._user);
      this.authToken = storedUser.token;
    }
  }

  public get user() {
    return AuthService._user;
  }

  public set user(user: any) {
    this.storeUser(user);
    this.user$.next(AuthService._user);
  }
  public get originalUser(): User {
    return this._originalUser;
  }

  public set originalUser(val: User) {
    this.storeOriginalUser(val);
  }
  public storeUser(user: any) {
    localStorage.setItem(
      "currentUser",
      JSON.stringify({
        user: user,
        token: user?.authToken,
      })
    );

    AuthService._user = { ...user };
    this.user$.next(user);
  }

  public storeOriginalUser(userData: User) {
    if (userData == null) {
      localStorage.removeItem("companyId");
      localStorage.removeItem("originalUser");
      return;
    } else
      localStorage.setItem(
        "originalUser",
        JSON.stringify({
          token: userData.authToken,
          user: userData,
        })
      );
    this._originalUser = userData;
    this.originalUser$.next(userData);
  }

  public login(username: string, password: string) {
    return this.apollo.mutate({
      mutation: gql`
        mutation loginUser($username: String!, $password: String!) {
          loginUser(username: $username, password: $password) {
            id
            email
            authToken
            languageId
            language {
              id
              isoCode2
              name
            }
            firstName
            lastName
            title
            isLocked
            emailConfirmed
            company {
              id
              name
              status
              shortName
            }
            companyId
            type
            permissions
            roleNames
          }
        }
      `,
      variables: {
        username: username,
        password: password,
      },
    });
  }

  public logout() {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("originalUser");
    localStorage.removeItem("companyId");

    AuthService._user = null;
    this.authToken = null;
    this.apollo.client.clearStore();
    this.apollo.client.resetStore();
    window.location.href = "/login";
    // this.router.navigate(['/login']);
  }

  public clearCache() {
    this.apollo.client.clearStore();
    this.apollo.client.resetStore();
  }

  public hasPermission(service: string | null = null, permission: string = "read") {
    let tmpPerms: any = [];
    permission = permission.toLowerCase();
    let serviceName = "p." + service?.toLocaleLowerCase() + ".";
    if (AuthService._user?.permissions == null) return false;

    AuthService._user?.permissions?.forEach((e: any) => {
      if (e.toLowerCase().includes(serviceName)) tmpPerms.push(e.toLowerCase());
    });

    switch (permission) {
      case "manage":
        return (
          tmpPerms?.includes(serviceName + "create") &&
          tmpPerms?.includes(serviceName + "read") &&
          tmpPerms?.includes(serviceName + "update") &&
          tmpPerms?.includes(serviceName + "delete")
        );
      case "overview":
        return tmpPerms?.includes(serviceName + "overview");
      case "status":
        return tmpPerms?.includes(serviceName + "status");
      default:
        return tmpPerms?.includes(serviceName + permission);
    }
  }

  public hasRole(role: RoleName | string): boolean {
    return this.user.roleNames?.includes(role);
  }

  public get isDoctor() {
    return this.hasRole(RoleName.Doctor);
  }

  public get isNurse() {
    return this.hasRole(RoleName.Nurse);
  }

  public get isDoctorOrNurse() {
    return this.isDoctor || this.isNurse;
  }
}
