<div fxLayout="column" fxLayoutAlign="center">
   <div fxLayout="row" class="timePicker">
      <div style="max-width: 50px;">
         <mat-icon (click)="changeHoursUp()">keyboard_arrow_up</mat-icon>
         <mat-form-field>
            <mat-label>{{"hh"|translate}}</mat-label>
            <input #textInput matInput type="text" maxlength="2" [formControl]="hours" style="width:100px">
         </mat-form-field>
         <mat-icon (click)="changeHoursDown()" style="margin-top: -20px;">keyboard_arrow_down</mat-icon>
      </div>
      <h2 style="margin-top: 20px; padding:20px;">:</h2>
      <div style="max-width: 50px;">
         <mat-icon (click)="changeMinutesUp()">keyboard_arrow_up</mat-icon>
         <mat-form-field>
            <mat-label>{{"mm"|translate}}</mat-label>
            <input matInput maxlength="2" type="text" [formControl]="minutes" style="width:100px">
         </mat-form-field>
         <mat-icon (click)="changeMinutesDown()" style="margin-top: -20px;">keyboard_arrow_down</mat-icon>
      </div>
   </div>
   <div fxLayout="row" style="margin-top: 8px;">
      <button mat-button mat-dialog-close (click)="dialogRef.close()">{{'Cancel' | translate}}</button>
      <button mat-button color="primary" (click)="formatTime()" [disabled]="hours.invalid || minutes.invalid">{{'Save' |
         translate}}</button>
   </div>
</div>