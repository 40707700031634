import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Unsubscribable, debounceTime, distinctUntilChanged, retry, startWith } from 'rxjs';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent implements OnInit, OnDestroy {

  @Input() patchHours!: string;
  @Input() patchMinutes!: string;
  hoursUnsub$!: Unsubscribable;
  minutesUnsub$!: Unsubscribable;


  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  hours = new FormControl(this.data.patchHours, [
    Validators.min(0),
    Validators.max(23),
    Validators.maxLength(2),
    Validators.required,
    Validators.pattern("^[0-9]*$"),
  ]);
  minutes = new FormControl(this.data.patchMinutes, [
    Validators.min(0),
    Validators.max(59),
    Validators.maxLength(2),
    Validators.required,
    Validators.pattern("^[0-9]*$")
  ]);
  ngOnInit(): void {
    this.hoursUnsub$ = this.hours.valueChanges.pipe(
      startWith(''),
      distinctUntilChanged(),
    ).subscribe(value => {
      if (value?.match((/[a-z]/i)))
        this.hours.reset();
    });
    this.minutesUnsub$ = this.minutes.valueChanges.pipe(
      startWith(''),
      distinctUntilChanged(),
    ).subscribe(value => {
      if (value?.match((/[a-z]/i)))
        this.minutes.reset();
    });
  }
  changeHoursUp() {
    let newStart: any = this.hours.value;
    if (newStart !== '23')
      this.hours.patchValue((+newStart + 1).toString().padStart(2, '0'));
    else this.hours.patchValue('00');
  }
  changeMinutesUp() {
    let newStart: any = this.minutes.value;
    if (newStart !== '59')
      this.minutes.patchValue((+newStart + 1).toString().padStart(2, '0'));
    else this.minutes.patchValue('00');
  }
  changeHoursDown() {
    let newStart: any = this.hours.value;
    if (newStart !== '00')
      this.hours.patchValue((+newStart - 1).toString().padStart(2, '0'));
    else this.hours.patchValue('23');
  }
  changeMinutesDown() {
    let newStart: any = this.minutes.value;
    if (newStart !== '00')
      this.minutes.patchValue((+newStart - 1).toString().padStart(2, '0'));
    else this.minutes.patchValue('59');
  }
  formatTime() {
    if (this.hours.value?.length === 1)
      this.hours.patchValue('0'.concat(this.hours.value!));
    if (this.minutes.value?.length === 1)
      this.minutes.patchValue('0'.concat(this.minutes.value!));
    this.dialogRef.close({ data: `${this.hours.value}:${this.minutes.value}:${'00'}` as any });
  }
  ngOnDestroy(): void {
    this.hoursUnsub$?.unsubscribe();
    this.minutesUnsub$?.unsubscribe();
  }
}
