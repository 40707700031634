import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, retry, startWith, Subscribable, Unsubscribable } from 'rxjs';
import { AuthService } from 'src/app/_services/auth.service';
import { UiService } from 'src/app/_services/ui.service';

@Component({
  selector: 'list-wrapper',
  templateUrl: './list-wrapper.component.html',
  styleUrls: ['./list-wrapper.component.scss']
})
export class ListWrapperComponent implements OnInit, OnDestroy {

  @Input('headers') headers: string[] = [];
  @Input('properties') properties: string[] = [];

  @Input('mainService') mainService: any;
  @Input('navigateItem') navigateItem!: string;
  @Input('title') title!: string;
  @Input('showPagination') showPagination: boolean = true;

  @Input('readPerm') readPerm: boolean = true;
  @Input('managePerm') managePerm: boolean = true;
  @Input('showDeleted') showDeleted: boolean = true;
  @Input('disableList') disableList: boolean = false;
  @Input('hideAddNew') hideAddNew: boolean = false;
  @Input('colorFirstColumn') colorFirstColumn: boolean = false;

  _showDeleted: boolean = false;

  search = new FormControl<string>('');
  searching$!: any;

  dataUnsub$!: Unsubscribable;
  $data!: Subscribable<any>;

  constructor(
    public ui: UiService,
    public router: Router,
    public auth: AuthService,
    private activatedRoute: ActivatedRoute,
  ) { }


  ngOnInit(): void {
    this.ui.setTitle(this.title);

    if (this.mainService) {
      this.mainService.queryParams.showDeleted = false;
      this.searching$ = this.search.valueChanges.pipe(
        startWith(''),
        distinctUntilChanged(),
        debounceTime(400),
        retry(2),
      ).subscribe(val => {
        this.mainService.queryParams.query = val;
        this.mainService.fetchMoreData();
      });
    }
    if (this.mainService) {
      this.mainService.queryParams.take = 25;
      if (!this.disableList)
        this.$data = this.mainService.all();
    }
  }


  public newItem() {
    this.router.navigate(['..', this.navigateItem, 'new'], { relativeTo: this.activatedRoute });
  }

  public edit(id: number | string | null | undefined) {
    var path = [id];
    this.router.navigate(path, { relativeTo: this.activatedRoute });
  }

  getDeleted() {
    this.mainService.pageIndex = 0;
    this.mainService.skip = 0;
    this._showDeleted = !this._showDeleted;
    this.mainService.showDeleted(this._showDeleted);
  }

  ngOnDestroy(): void {
    this.mainService.queryParams.query = '';
    this.mainService.fetchMoreData();
    this.searching$?.unsubscribe();
  }
}


