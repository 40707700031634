import { gql } from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Date` scalar type represents a year, month and day in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. Format is `yyyy-MM-dd` */
  DateOnly: any;
  /** The `DateTime` scalar type represents a date and time. `DateTime` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  DateTime: any;
  Decimal: any;
  LocalDateTime: any;
  /** The `Time` scalar type represents a time in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. Format is `HH:mm:ss.FFFFFFF`. */
  TimeOnly: any;
  WeekDay: any;
};

export enum AmbulantTimeConfiguration {
  Before_15 = 'BEFORE_15',
  Before_30 = 'BEFORE_30',
  Before_60 = 'BEFORE_60',
  UnitStartTime = 'UNIT_START_TIME'
}

export type Appointment = {
  __typename?: 'Appointment';
  ambulantTime?: Maybe<Scalars['LocalDateTime']>;
  appointedByUser?: Maybe<User>;
  appointedByUserId?: Maybe<Scalars['ID']>;
  appointedTime?: Maybe<Scalars['LocalDateTime']>;
  appointedToDoctor?: Maybe<User>;
  appointedToDoctorId?: Maybe<Scalars['ID']>;
  appointedToUnit?: Maybe<Unit>;
  appointedToUnitId?: Maybe<Scalars['ID']>;
  appointmentDue?: Maybe<Scalars['LocalDateTime']>;
  appointmentType?: Maybe<AppointmentType>;
  appointmentTypeId?: Maybe<Scalars['ID']>;
  arrangementNote?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['ID']>;
  controlAppointmentDays?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['DateTime']>;
  deleted?: Maybe<Scalars['DateTime']>;
  expectedAppointmentTime?: Maybe<Scalars['DateTime']>;
  firstAppointmentDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  isArranged?: Maybe<Scalars['Boolean']>;
  isControlAppointment?: Maybe<Scalars['Boolean']>;
  isFirstTimeAppointment?: Maybe<Scalars['Boolean']>;
  modified?: Maybe<Scalars['DateTime']>;
  patient?: Maybe<Patient>;
  patientId?: Maybe<Scalars['ID']>;
  patientNotes?: Maybe<Scalars['String']>;
  preferedDoctor?: Maybe<User>;
  preferedDoctorId?: Maybe<Scalars['ID']>;
  prefferedDateTime?: Maybe<Scalars['DateTime']>;
  priority?: Maybe<Scalars['Decimal']>;
  scheduledTime?: Maybe<Scalars['LocalDateTime']>;
  scheduledTimeTable?: Maybe<ScheduledTimeTable>;
  scheduledTimeTableId?: Maybe<Scalars['ID']>;
  schedulingCriteria?: Maybe<Scalars['String']>;
  source?: Maybe<AppointmentSource>;
  status?: Maybe<AppointmentStatus>;
  statusNote?: Maybe<Scalars['String']>;
};

export type AppointmentEvent = {
  __typename?: 'AppointmentEvent';
  callPatient?: Maybe<Scalars['Boolean']>;
  customMessageUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isArranged?: Maybe<Scalars['Boolean']>;
  patientFirstName?: Maybe<Scalars['String']>;
  patientLastName?: Maybe<Scalars['String']>;
  refetch?: Maybe<Scalars['Boolean']>;
  status?: Maybe<AppointmentStatus>;
  unitId?: Maybe<Scalars['ID']>;
  unitName?: Maybe<Scalars['String']>;
  unitShortName?: Maybe<Scalars['String']>;
};

export type AppointmentInput = {
  ambulantTime?: InputMaybe<Scalars['LocalDateTime']>;
  appointedTime?: InputMaybe<Scalars['LocalDateTime']>;
  appointedToDoctorId?: InputMaybe<Scalars['ID']>;
  appointedToUnitId?: InputMaybe<Scalars['ID']>;
  appointmentDue?: InputMaybe<Scalars['LocalDateTime']>;
  appointmentKind?: InputMaybe<AppointmentTypeKind>;
  appointmentTypeId?: InputMaybe<Scalars['ID']>;
  controlAppointmentDays?: InputMaybe<Scalars['Int']>;
  firstAppointmentDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  isArranged?: InputMaybe<Scalars['Boolean']>;
  isControlAppointment?: InputMaybe<Scalars['Boolean']>;
  isFirstTimeAppointment?: InputMaybe<Scalars['Boolean']>;
  patientId?: InputMaybe<Scalars['ID']>;
  patientNotes?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  scheduledTime?: InputMaybe<Scalars['LocalDateTime']>;
  scheduledTimeTableId?: InputMaybe<Scalars['ID']>;
  source?: InputMaybe<AppointmentSource>;
  status?: InputMaybe<AppointmentStatus>;
  statusNote?: InputMaybe<Scalars['String']>;
};

export type AppointmentScreen = {
  __typename?: 'AppointmentScreen';
  id?: Maybe<Scalars['ID']>;
  isArranged?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  patient?: Maybe<Patient>;
  status?: Maybe<AppointmentStatus>;
};

export enum AppointmentSource {
  Auto = 'AUTO',
  Manual = 'MANUAL',
  ManualNoTimeSlot = 'MANUAL_NO_TIME_SLOT'
}

export enum AppointmentStatus {
  Arrived = 'ARRIVED',
  CanceledByDoctor = 'CANCELED_BY_DOCTOR',
  CanceledByOperator = 'CANCELED_BY_OPERATOR',
  CanceledByPatient = 'CANCELED_BY_PATIENT',
  Confirmed = 'CONFIRMED',
  Initiated = 'INITIATED',
  InProgress = 'IN_PROGRESS',
  NoShowUp = 'NO_SHOW_UP',
  Scheduled = 'SCHEDULED',
  Visited = 'VISITED'
}

export type AppointmentType = {
  __typename?: 'AppointmentType';
  appointmentKind?: Maybe<AppointmentTypeKind>;
  created?: Maybe<Scalars['DateTime']>;
  deleted?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  instructions?: Maybe<Scalars['String']>;
  modified?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  questions?: Maybe<Scalars['String']>;
  units?: Maybe<Array<Maybe<Unit>>>;
};

export type AppointmentTypeInput = {
  appointmentKind?: InputMaybe<AppointmentTypeKind>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  instructions?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  questions?: InputMaybe<Scalars['String']>;
  units?: InputMaybe<Array<InputMaybe<UnitInput>>>;
};

export enum AppointmentTypeKind {
  Regular = 'REGULAR',
  RegularAndSpecial = 'REGULAR_AND_SPECIAL',
  Special = 'SPECIAL'
}

export type AppointmentTypeRoleInput = {
  appointmentTypeId?: InputMaybe<Scalars['ID']>;
};

export type AppointmentTypeRoleType = {
  __typename?: 'AppointmentTypeRoleType';
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type AvailableSlot = {
  __typename?: 'AvailableSlot';
  available?: Maybe<Scalars['DateTime']>;
  doctorId?: Maybe<Scalars['ID']>;
  timeTableId?: Maybe<Scalars['ID']>;
  unitId?: Maybe<Scalars['ID']>;
};

export type City = {
  __typename?: 'City';
  countryId?: Maybe<Scalars['ID']>;
  created?: Maybe<Scalars['DateTime']>;
  deleted?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  modified?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
};

export type CityInput = {
  countryId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type Company = {
  __typename?: 'Company';
  adminNote?: Maybe<Scalars['String']>;
  avatar?: Maybe<Document>;
  avatarId?: Maybe<Scalars['ID']>;
  country?: Maybe<Country>;
  countryId?: Maybe<Scalars['ID']>;
  cover?: Maybe<Document>;
  coverId?: Maybe<Scalars['ID']>;
  created?: Maybe<Scalars['DateTime']>;
  deleted?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directorName?: Maybe<Scalars['String']>;
  directorPhone?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  idNumber?: Maybe<Scalars['String']>;
  modified?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  regNumber?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  status?: Maybe<CompanyStatus>;
  statusUserNote?: Maybe<Scalars['String']>;
  taxNumber?: Maybe<Scalars['String']>;
  users?: Maybe<Array<Maybe<User>>>;
};

export type CompanyInput = {
  adminNote?: InputMaybe<Scalars['String']>;
  avatarId?: InputMaybe<Scalars['ID']>;
  contactPersonName?: InputMaybe<Scalars['String']>;
  contactPersonPosition?: InputMaybe<Scalars['String']>;
  countryId?: InputMaybe<Scalars['ID']>;
  coverId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  directorName?: InputMaybe<Scalars['String']>;
  directorPhone?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  idNumber?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  regNumber?: InputMaybe<Scalars['String']>;
  shortName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<CompanyStatus>;
  statusUserNote?: InputMaybe<Scalars['String']>;
  taxNumber?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<Array<InputMaybe<UserInput>>>;
};

export enum CompanyStatus {
  Active = 'ACTIVE',
  Applied = 'APPLIED',
  Approved = 'APPROVED',
  Canceled = 'CANCELED',
  Declined = 'DECLINED',
  Onboarding = 'ONBOARDING',
  Paused = 'PAUSED',
  Suspended = 'SUSPENDED'
}

export type Country = {
  __typename?: 'Country';
  created?: Maybe<Scalars['DateTime']>;
  deleted?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isoCode2?: Maybe<Scalars['String']>;
  isoCode3?: Maybe<Scalars['String']>;
  modified?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
};

export type CountryInput = {
  id?: InputMaybe<Scalars['ID']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  isoCode2?: InputMaybe<Scalars['String']>;
  isoCode3?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  order?: InputMaybe<Scalars['Int']>;
};

export type CustomMessage = {
  __typename?: 'CustomMessage';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type Document = {
  __typename?: 'Document';
  bigimage?: Maybe<Scalars['String']>;
  blobName?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  thumbnail?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type EmptySlot = {
  __typename?: 'EmptySlot';
  doctor?: Maybe<User>;
  doctorId?: Maybe<Scalars['ID']>;
  freeDate?: Maybe<Scalars['DateOnly']>;
  freeTimeEnd?: Maybe<Scalars['TimeOnly']>;
  freeTimeStart?: Maybe<Scalars['TimeOnly']>;
  source?: Maybe<Scalars['String']>;
  unit?: Maybe<Unit>;
  unitId?: Maybe<Scalars['ID']>;
};

export enum GroupType {
  Doctor = 'DOCTOR',
  Patient = 'PATIENT',
  Status = 'STATUS',
  Type = 'TYPE',
  Unit = 'UNIT'
}

export type GroupedInfo = {
  __typename?: 'GroupedInfo';
  count?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Language = {
  __typename?: 'Language';
  id: Scalars['Int'];
  isDefault: Scalars['Boolean'];
  isoCode2: Scalars['String'];
  isoCode3?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type LanguageInput = {
  id?: InputMaybe<Scalars['ID']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  isoCode2?: InputMaybe<Scalars['String']>;
  isoCode3?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type Mutations = {
  __typename?: 'Mutations';
  acceptInvitation?: Maybe<User>;
  addCompanyNote?: Maybe<Company>;
  callNextPatient?: Maybe<Scalars['Boolean']>;
  changePassword?: Maybe<Scalars['Boolean']>;
  createAppointment?: Maybe<Appointment>;
  createAppointmentType?: Maybe<AppointmentType>;
  createCity?: Maybe<City>;
  createCompany?: Maybe<Company>;
  createCountry?: Maybe<Country>;
  createCustomMessage?: Maybe<CustomMessage>;
  createLanguage?: Maybe<Language>;
  createPatient?: Maybe<Patient>;
  createRole?: Maybe<Role>;
  createScheduledTimeTable?: Maybe<ScheduledTimeTable>;
  createTranslation?: Maybe<Translation>;
  createUnit?: Maybe<Unit>;
  createUpdateTranslations?: Maybe<Array<Maybe<Translation>>>;
  createUser?: Maybe<User>;
  deleteAppointment?: Maybe<Appointment>;
  deleteAppointmentType?: Maybe<AppointmentType>;
  deleteCity?: Maybe<City>;
  deleteCompany?: Maybe<Company>;
  deleteCountry?: Maybe<Country>;
  deleteCustomMessage?: Maybe<CustomMessage>;
  deleteLanguage?: Maybe<Language>;
  deletePatient?: Maybe<Patient>;
  deleteScheduledTimeTable?: Maybe<ScheduledTimeTable>;
  deleteTranslation?: Maybe<Translation>;
  deleteUnit?: Maybe<Unit>;
  deleteUser?: Maybe<User>;
  existingUser?: Maybe<Scalars['Boolean']>;
  generateDummy?: Maybe<Scalars['Boolean']>;
  lockUser?: Maybe<User>;
  loginUser?: Maybe<User>;
  playCustomMessage?: Maybe<Scalars['Boolean']>;
  register?: Maybe<User>;
  requestResetPasswordUser?: Maybe<Scalars['Boolean']>;
  resendInvite?: Maybe<User>;
  resendVerificationEndUser?: Maybe<User>;
  resetPasswordUser?: Maybe<Scalars['Boolean']>;
  restoreAppointment?: Maybe<Appointment>;
  restoreAppointmentType?: Maybe<AppointmentType>;
  restoreCity?: Maybe<City>;
  restoreCompany?: Maybe<Company>;
  restoreCountry?: Maybe<Country>;
  restoreLanguage?: Maybe<Language>;
  restorePatient?: Maybe<Patient>;
  restoreScheduledTimeTable?: Maybe<ScheduledTimeTable>;
  restoreTranslation?: Maybe<Translation>;
  restoreUnit?: Maybe<Unit>;
  restoreUser?: Maybe<User>;
  scheduleAppointments?: Maybe<Array<Maybe<Appointment>>>;
  scheduleAppointmentsV2?: Maybe<Array<Maybe<Appointment>>>;
  setAppointmentStatus?: Maybe<Appointment>;
  setArrangedAppointment?: Maybe<Appointment>;
  setCompanyStatus?: Maybe<Company>;
  setPermissions?: Maybe<User>;
  setUserRoles?: Maybe<User>;
  /** For admins - to set up other companies and see reportings */
  switchCompany?: Maybe<User>;
  updateAppointment?: Maybe<Appointment>;
  updateAppointmentType?: Maybe<AppointmentType>;
  updateCity?: Maybe<City>;
  updateCompany?: Maybe<Company>;
  updateCountry?: Maybe<Country>;
  updateLanguage?: Maybe<Language>;
  updatePatient?: Maybe<Patient>;
  updateRole?: Maybe<Role>;
  updateScheduledTimeTable?: Maybe<ScheduledTimeTable>;
  updateTranslation?: Maybe<Translation>;
  updateUnit?: Maybe<Unit>;
  updateUser?: Maybe<User>;
  verifyEndUser?: Maybe<User>;
};


export type MutationsAcceptInvitationArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationsAddCompanyNoteArgs = {
  companyId: Scalars['ID'];
  note: Scalars['String'];
};


export type MutationsCallNextPatientArgs = {
  appointmentId?: InputMaybe<Scalars['ID']>;
  unitId: Scalars['ID'];
};


export type MutationsChangePasswordArgs = {
  oldPassword: Scalars['String'];
  password: Scalars['String'];
};


export type MutationsCreateAppointmentArgs = {
  item: AppointmentInput;
};


export type MutationsCreateAppointmentTypeArgs = {
  item: AppointmentTypeInput;
};


export type MutationsCreateCityArgs = {
  item: CityInput;
};


export type MutationsCreateCompanyArgs = {
  item: CompanyInput;
};


export type MutationsCreateCountryArgs = {
  item: CountryInput;
};


export type MutationsCreateCustomMessageArgs = {
  messageName: Scalars['String'];
  messageText: Scalars['String'];
};


export type MutationsCreateLanguageArgs = {
  item: LanguageInput;
};


export type MutationsCreatePatientArgs = {
  item: PatientInput;
};


export type MutationsCreateRoleArgs = {
  item: RoleInput;
};


export type MutationsCreateScheduledTimeTableArgs = {
  item: ScheduledTimeTableInput;
};


export type MutationsCreateTranslationArgs = {
  item: TranslationInput;
};


export type MutationsCreateUnitArgs = {
  item: UnitInput;
};


export type MutationsCreateUpdateTranslationsArgs = {
  items: Array<InputMaybe<TranslationInput>>;
};


export type MutationsCreateUserArgs = {
  item: UserInput;
  roles?: InputMaybe<Array<InputMaybe<RoleName>>>;
};


export type MutationsDeleteAppointmentArgs = {
  id: Scalars['ID'];
};


export type MutationsDeleteAppointmentTypeArgs = {
  id: Scalars['ID'];
};


export type MutationsDeleteCityArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationsDeleteCompanyArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationsDeleteCountryArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationsDeleteCustomMessageArgs = {
  id: Scalars['ID'];
};


export type MutationsDeleteLanguageArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationsDeletePatientArgs = {
  id: Scalars['ID'];
};


export type MutationsDeleteScheduledTimeTableArgs = {
  id: Scalars['ID'];
};


export type MutationsDeleteTranslationArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationsDeleteUnitArgs = {
  id: Scalars['ID'];
};


export type MutationsDeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationsExistingUserArgs = {
  email: Scalars['String'];
};


export type MutationsGenerateDummyArgs = {
  appointmentDue?: InputMaybe<Scalars['DateTime']>;
  numAppointments: Scalars['Int'];
};


export type MutationsLockUserArgs = {
  lock: Scalars['Boolean'];
  userId: Scalars['ID'];
};


export type MutationsLoginUserArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};


export type MutationsPlayCustomMessageArgs = {
  messageId: Scalars['ID'];
  unitId: Scalars['ID'];
};


export type MutationsRegisterArgs = {
  registration: RegisterUserInput;
};


export type MutationsRequestResetPasswordUserArgs = {
  username: Scalars['String'];
};


export type MutationsResendInviteArgs = {
  userId: Scalars['ID'];
};


export type MutationsResetPasswordUserArgs = {
  newPassword: Scalars['String'];
  username: Scalars['String'];
  verificationCode: Scalars['String'];
};


export type MutationsRestoreAppointmentArgs = {
  id: Scalars['ID'];
};


export type MutationsRestoreAppointmentTypeArgs = {
  id: Scalars['ID'];
};


export type MutationsRestoreCityArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationsRestoreCompanyArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationsRestoreCountryArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationsRestoreLanguageArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationsRestorePatientArgs = {
  id: Scalars['ID'];
};


export type MutationsRestoreScheduledTimeTableArgs = {
  id: Scalars['ID'];
};


export type MutationsRestoreTranslationArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationsRestoreUnitArgs = {
  id: Scalars['ID'];
};


export type MutationsRestoreUserArgs = {
  id: Scalars['ID'];
};


export type MutationsScheduleAppointmentsArgs = {
  doctorId?: InputMaybe<Scalars['ID']>;
  endDate: Scalars['DateOnly'];
  startDate: Scalars['DateOnly'];
  unitId: Scalars['ID'];
};


export type MutationsScheduleAppointmentsV2Args = {
  doctorId?: InputMaybe<Scalars['ID']>;
  endDate: Scalars['DateOnly'];
  startDate: Scalars['DateOnly'];
  unitId?: InputMaybe<Scalars['ID']>;
};


export type MutationsSetAppointmentStatusArgs = {
  id: Scalars['ID'];
  status: AppointmentStatus;
};


export type MutationsSetArrangedAppointmentArgs = {
  id: Scalars['ID'];
  note: Scalars['String'];
};


export type MutationsSetCompanyStatusArgs = {
  adminMessage?: InputMaybe<Scalars['String']>;
  companyId: Scalars['ID'];
  newStatus: CompanyStatus;
  statusUserMessage?: InputMaybe<Scalars['String']>;
};


export type MutationsSetPermissionsArgs = {
  allowed?: InputMaybe<Array<InputMaybe<RoleName>>>;
  denied?: InputMaybe<Array<InputMaybe<RoleName>>>;
  userId: Scalars['ID'];
};


export type MutationsSetUserRolesArgs = {
  roles: Array<InputMaybe<RoleName>>;
  userId: Scalars['ID'];
};


export type MutationsSwitchCompanyArgs = {
  companyId: Scalars['ID'];
};


export type MutationsUpdateAppointmentArgs = {
  item: AppointmentInput;
};


export type MutationsUpdateAppointmentTypeArgs = {
  item: AppointmentTypeInput;
};


export type MutationsUpdateCityArgs = {
  item: CityInput;
};


export type MutationsUpdateCompanyArgs = {
  item: CompanyInput;
};


export type MutationsUpdateCountryArgs = {
  item: CountryInput;
};


export type MutationsUpdateLanguageArgs = {
  item: LanguageInput;
};


export type MutationsUpdatePatientArgs = {
  item: PatientInput;
};


export type MutationsUpdateRoleArgs = {
  item: RoleInput;
};


export type MutationsUpdateScheduledTimeTableArgs = {
  item: ScheduledTimeTableInput;
};


export type MutationsUpdateTranslationArgs = {
  item: TranslationInput;
};


export type MutationsUpdateUnitArgs = {
  item: UnitInput;
};


export type MutationsUpdateUserArgs = {
  item: UserInput;
};


export type MutationsVerifyEndUserArgs = {
  email: Scalars['String'];
  verificationCode: Scalars['String'];
};

export type PageRequestInput = {
  countryId?: InputMaybe<Scalars['ID']>;
  doctorId?: InputMaybe<Scalars['ID']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  query?: InputMaybe<Scalars['String']>;
  showDeleted?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  statsFrom?: InputMaybe<Scalars['DateTime']>;
  statsTo?: InputMaybe<Scalars['DateTime']>;
  take?: InputMaybe<Scalars['Int']>;
  unitId?: InputMaybe<Scalars['ID']>;
};

export type PaginatorAppointmentGType = {
  __typename?: 'PaginatorAppointmentGType';
  data?: Maybe<Array<Maybe<Appointment>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginatorAppointmentTypeGType = {
  __typename?: 'PaginatorAppointmentTypeGType';
  data?: Maybe<Array<Maybe<AppointmentType>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginatorCityGType = {
  __typename?: 'PaginatorCityGType';
  data?: Maybe<Array<Maybe<City>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginatorCompanyGType = {
  __typename?: 'PaginatorCompanyGType';
  data?: Maybe<Array<Maybe<Company>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginatorCountryGType = {
  __typename?: 'PaginatorCountryGType';
  data?: Maybe<Array<Maybe<Country>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginatorLangGType = {
  __typename?: 'PaginatorLangGType';
  data?: Maybe<Array<Maybe<Language>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginatorPatientGType = {
  __typename?: 'PaginatorPatientGType';
  data?: Maybe<Array<Maybe<Patient>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginatorRoleGType = {
  __typename?: 'PaginatorRoleGType';
  data?: Maybe<Array<Maybe<Role>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginatorScheduledTimeTableGType = {
  __typename?: 'PaginatorScheduledTimeTableGType';
  data?: Maybe<Array<Maybe<ScheduledTimeTable>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginatorTransGType = {
  __typename?: 'PaginatorTransGType';
  data?: Maybe<Array<Maybe<Translation>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginatorTransLangGType = {
  __typename?: 'PaginatorTransLangGType';
  data?: Maybe<Array<Maybe<TranslationLanguage>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginatorUnitGType = {
  __typename?: 'PaginatorUnitGType';
  data?: Maybe<Array<Maybe<Unit>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginatorUserGType = {
  __typename?: 'PaginatorUserGType';
  data?: Maybe<Array<Maybe<User>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type Patient = {
  __typename?: 'Patient';
  city?: Maybe<City>;
  cityId?: Maybe<Scalars['ID']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['ID']>;
  country?: Maybe<Country>;
  countryId?: Maybe<Scalars['ID']>;
  created?: Maybe<Scalars['DateTime']>;
  deleted?: Maybe<Scalars['DateTime']>;
  documentId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
  modified?: Maybe<Scalars['DateTime']>;
  noShowUpCount?: Maybe<Scalars['Int']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  uIDN?: Maybe<Scalars['String']>;
  viber?: Maybe<Scalars['String']>;
};

export type PatientInput = {
  cityId?: InputMaybe<Scalars['ID']>;
  companyId?: InputMaybe<Scalars['ID']>;
  countryId?: InputMaybe<Scalars['ID']>;
  documentId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  facebook?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  iAgree?: InputMaybe<Scalars['DateTime']>;
  iAgreeUserId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  lastName?: InputMaybe<Scalars['String']>;
  noShowUpCount?: InputMaybe<Scalars['Int']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  street2?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  uIDN?: InputMaybe<Scalars['String']>;
  viber?: InputMaybe<Scalars['String']>;
};

export type Queries = {
  __typename?: 'Queries';
  appointment?: Maybe<Appointment>;
  appointmentType?: Maybe<AppointmentType>;
  appointmentTypes?: Maybe<PaginatorAppointmentTypeGType>;
  appointments?: Maybe<PaginatorAppointmentGType>;
  availableRoles?: Maybe<Array<Maybe<RoleName>>>;
  availableSlots?: Maybe<Array<Maybe<AvailableSlot>>>;
  cities?: Maybe<PaginatorCityGType>;
  city?: Maybe<City>;
  companies?: Maybe<PaginatorCompanyGType>;
  company?: Maybe<Company>;
  companyUnits?: Maybe<Array<Maybe<Unit>>>;
  countries?: Maybe<PaginatorCountryGType>;
  country?: Maybe<Country>;
  currentUser?: Maybe<User>;
  customMessages?: Maybe<Array<Maybe<CustomMessage>>>;
  emptySlots?: Maybe<Array<Maybe<EmptySlot>>>;
  filteredAppointments?: Maybe<PaginatorAppointmentGType>;
  getAudioPath?: Maybe<Scalars['String']>;
  getFromUnit?: Maybe<PaginatorUserGType>;
  getFromUnitTest?: Maybe<PaginatorUserGType>;
  language?: Maybe<Language>;
  languages?: Maybe<PaginatorLangGType>;
  patient?: Maybe<Patient>;
  patientAppointments?: Maybe<Array<Maybe<Appointment>>>;
  patients?: Maybe<PaginatorPatientGType>;
  role?: Maybe<Role>;
  roles?: Maybe<PaginatorRoleGType>;
  scheduledTimeTable?: Maybe<ScheduledTimeTable>;
  scheduledTimeTables?: Maybe<PaginatorScheduledTimeTableGType>;
  screenData?: Maybe<Array<Maybe<UnitScreen>>>;
  stats?: Maybe<StatInfo>;
  translation?: Maybe<Translation>;
  translations?: Maybe<PaginatorTransLangGType>;
  translationsByKey?: Maybe<Array<Maybe<Translation>>>;
  translationsByLanguage?: Maybe<Array<Maybe<Translation>>>;
  translationsPlain?: Maybe<PaginatorTransGType>;
  unit?: Maybe<Unit>;
  units?: Maybe<PaginatorUnitGType>;
  user?: Maybe<User>;
  users?: Maybe<PaginatorUserGType>;
  waitingListAppointments?: Maybe<PaginatorAppointmentGType>;
};


export type QueriesAppointmentArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueriesAppointmentTypeArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueriesAppointmentTypesArgs = {
  pageRequestInput?: InputMaybe<PageRequestInput>;
};


export type QueriesAppointmentsArgs = {
  pageRequestInput?: InputMaybe<PageRequestInput>;
};


export type QueriesAvailableSlotsArgs = {
  appointmentTypeId: Scalars['ID'];
  doctorId?: InputMaybe<Scalars['ID']>;
  endDate?: InputMaybe<Scalars['DateOnly']>;
  numSlots?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['DateOnly']>;
  unitId?: InputMaybe<Scalars['ID']>;
};


export type QueriesCitiesArgs = {
  pageRequestInput?: InputMaybe<PageRequestInput>;
};


export type QueriesCityArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueriesCompaniesArgs = {
  pageRequestInput?: InputMaybe<PageRequestInput>;
};


export type QueriesCompanyArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueriesCompanyUnitsArgs = {
  companyId: Scalars['ID'];
  userId?: InputMaybe<Scalars['ID']>;
};


export type QueriesCountriesArgs = {
  pageRequestInput?: InputMaybe<PageRequestInput>;
};


export type QueriesCountryArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueriesEmptySlotsArgs = {
  endDate?: InputMaybe<Scalars['DateOnly']>;
  startDate?: InputMaybe<Scalars['DateOnly']>;
};


export type QueriesFilteredAppointmentsArgs = {
  appointmentSatus?: InputMaybe<AppointmentStatus>;
  doctorId?: InputMaybe<Scalars['ID']>;
  endDate?: InputMaybe<Scalars['DateOnly']>;
  pageRequestInput?: InputMaybe<PageRequestInput>;
  patientId?: InputMaybe<Scalars['ID']>;
  startDate?: InputMaybe<Scalars['DateOnly']>;
  unitId?: InputMaybe<Scalars['ID']>;
};


export type QueriesGetAudioPathArgs = {
  text: Scalars['String'];
};


export type QueriesGetFromUnitArgs = {
  companyId?: InputMaybe<Scalars['ID']>;
  pageRequestInput?: InputMaybe<PageRequestInput>;
  unitId?: InputMaybe<Scalars['ID']>;
  userType?: InputMaybe<Array<InputMaybe<UserType>>>;
};


export type QueriesGetFromUnitTestArgs = {
  companyId?: InputMaybe<Scalars['ID']>;
  pageRequestInput?: InputMaybe<PageRequestInput>;
  unitId?: InputMaybe<Scalars['ID']>;
  userType?: InputMaybe<Array<InputMaybe<UserType>>>;
};


export type QueriesLanguageArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueriesLanguagesArgs = {
  pageRequestInput?: InputMaybe<PageRequestInput>;
};


export type QueriesPatientArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueriesPatientAppointmentsArgs = {
  patientId: Scalars['ID'];
};


export type QueriesPatientsArgs = {
  pageRequestInput?: InputMaybe<PageRequestInput>;
};


export type QueriesRoleArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueriesRolesArgs = {
  pageRequestInput?: InputMaybe<PageRequestInput>;
};


export type QueriesScheduledTimeTableArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueriesScheduledTimeTablesArgs = {
  doctorId?: InputMaybe<Scalars['ID']>;
  endDate?: InputMaybe<Scalars['DateOnly']>;
  pageRequestInput?: InputMaybe<PageRequestInput>;
  startDate?: InputMaybe<Scalars['DateOnly']>;
  unitId?: InputMaybe<Scalars['ID']>;
};


export type QueriesScreenDataArgs = {
  unitIds: Array<InputMaybe<Scalars['ID']>>;
};


export type QueriesStatsArgs = {
  appointmentStatus?: InputMaybe<AppointmentStatus>;
  appointmentTypeId?: InputMaybe<Scalars['ID']>;
  doctorId?: InputMaybe<Scalars['ID']>;
  end: Scalars['DateTime'];
  groupBy: GroupType;
  patientId?: InputMaybe<Scalars['ID']>;
  start: Scalars['DateTime'];
  topk?: InputMaybe<Scalars['Int']>;
  unitId?: InputMaybe<Scalars['ID']>;
};


export type QueriesTranslationArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueriesTranslationsArgs = {
  pageRequestInput?: InputMaybe<PageRequestInput>;
};


export type QueriesTranslationsByKeyArgs = {
  key: Scalars['String'];
};


export type QueriesTranslationsByLanguageArgs = {
  isoCode2?: InputMaybe<Scalars['String']>;
};


export type QueriesTranslationsPlainArgs = {
  pageRequestInput?: InputMaybe<PageRequestInput>;
};


export type QueriesUnitArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueriesUnitsArgs = {
  pageRequestInput?: InputMaybe<PageRequestInput>;
};


export type QueriesUserArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueriesUsersArgs = {
  pageRequestInput?: InputMaybe<PageRequestInput>;
};


export type QueriesWaitingListAppointmentsArgs = {
  companyId?: InputMaybe<Scalars['ID']>;
  doctorId?: InputMaybe<Scalars['ID']>;
  endDate?: InputMaybe<Scalars['DateOnly']>;
  includeScheduled?: InputMaybe<Scalars['Boolean']>;
  pageRequestInput?: InputMaybe<PageRequestInput>;
  startDate?: InputMaybe<Scalars['DateOnly']>;
  unitId?: InputMaybe<Scalars['ID']>;
};

export type RegisterUserInput = {
  companyAddress?: InputMaybe<Scalars['String']>;
  companyCityId?: InputMaybe<Scalars['ID']>;
  companyEmail?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['String']>;
  companyName: Scalars['String'];
  companyPhone?: InputMaybe<Scalars['String']>;
  companyPostalCode?: InputMaybe<Scalars['String']>;
  companyShortName?: InputMaybe<Scalars['String']>;
  iAgree?: InputMaybe<Scalars['Boolean']>;
  userEmail: Scalars['String'];
  userFirstName: Scalars['String'];
  userLastName: Scalars['String'];
  userPhone: Scalars['String'];
};

export type Role = {
  __typename?: 'Role';
  appointmentTypes?: Maybe<Array<Maybe<AppointmentTypeRoleType>>>;
  created?: Maybe<Scalars['DateTime']>;
  deleted?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  modified?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  normalizedName: Scalars['String'];
};

export type RoleInput = {
  appointmentTypes?: InputMaybe<Array<InputMaybe<AppointmentTypeRoleInput>>>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
};

export enum RoleName {
  CompanyAdmin = 'COMPANY_ADMIN',
  Doctor = 'DOCTOR',
  Nurse = 'NURSE',
  Operator = 'OPERATOR',
  SpecialOperator = 'SPECIAL_OPERATOR',
  SuperAdmin = 'SUPER_ADMIN'
}

export type ScheduledTimeTable = {
  __typename?: 'ScheduledTimeTable';
  appointments?: Maybe<Array<Maybe<Appointment>>>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['ID']>;
  created?: Maybe<Scalars['DateTime']>;
  deleted?: Maybe<Scalars['DateTime']>;
  doctor?: Maybe<User>;
  doctorId?: Maybe<Scalars['ID']>;
  endDate?: Maybe<Scalars['DateOnly']>;
  endTime?: Maybe<Scalars['TimeOnly']>;
  id?: Maybe<Scalars['ID']>;
  modified?: Maybe<Scalars['DateTime']>;
  note?: Maybe<Scalars['String']>;
  pauseEnd?: Maybe<Scalars['TimeOnly']>;
  pauseStart?: Maybe<Scalars['TimeOnly']>;
  priority?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['DateOnly']>;
  startTime?: Maybe<Scalars['TimeOnly']>;
  unit?: Maybe<Unit>;
  unitId?: Maybe<Scalars['ID']>;
  weekDays?: Maybe<Scalars['WeekDay']>;
};

export type ScheduledTimeTableInput = {
  companyId?: InputMaybe<Scalars['ID']>;
  doctorId?: InputMaybe<Scalars['ID']>;
  endDate?: InputMaybe<Scalars['DateOnly']>;
  endTime?: InputMaybe<Scalars['TimeOnly']>;
  id?: InputMaybe<Scalars['ID']>;
  note?: InputMaybe<Scalars['String']>;
  pauseEnd?: InputMaybe<Scalars['TimeOnly']>;
  pauseStart?: InputMaybe<Scalars['TimeOnly']>;
  priority?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['DateOnly']>;
  startTime?: InputMaybe<Scalars['TimeOnly']>;
  unitId?: InputMaybe<Scalars['ID']>;
  weekDays?: InputMaybe<Scalars['WeekDay']>;
};

export type StatInfo = {
  __typename?: 'StatInfo';
  appointments?: Maybe<Array<Maybe<Appointment>>>;
  count?: Maybe<Scalars['Int']>;
  grouped?: Maybe<Array<Maybe<GroupedInfo>>>;
};

export type Subscriptions = {
  __typename?: 'Subscriptions';
  newAppointmentEvent?: Maybe<AppointmentEvent>;
};


export type SubscriptionsNewAppointmentEventArgs = {
  unitIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type Translation = {
  __typename?: 'Translation';
  id?: Maybe<Scalars['ID']>;
  key: Scalars['String'];
  language?: Maybe<Language>;
  languageId: Scalars['Int'];
  languageName?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

export type TranslationInput = {
  id?: InputMaybe<Scalars['ID']>;
  key: Scalars['String'];
  languageId: Scalars['Int'];
  value: Scalars['String'];
};

export type TranslationLanguage = {
  __typename?: 'TranslationLanguage';
  id?: Maybe<Scalars['ID']>;
  key: Scalars['String'];
  translations?: Maybe<Array<Maybe<Translation>>>;
};

export type Unit = {
  __typename?: 'Unit';
  ambulantTimeConfiguration?: Maybe<AmbulantTimeConfiguration>;
  appointmentTypes?: Maybe<Array<Maybe<UnitAppointmentType>>>;
  avatar?: Maybe<Document>;
  avatarId?: Maybe<Scalars['ID']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['ID']>;
  contactPersonName?: Maybe<Scalars['String']>;
  contactPersonPosition?: Maybe<Scalars['String']>;
  cover?: Maybe<Document>;
  coverId?: Maybe<Scalars['ID']>;
  created?: Maybe<Scalars['DateTime']>;
  deleted?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['TimeOnly']>;
  id?: Maybe<Scalars['ID']>;
  initiatedCount?: Maybe<Scalars['Int']>;
  modified?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  pauseEnd?: Maybe<Scalars['TimeOnly']>;
  pauseStart?: Maybe<Scalars['TimeOnly']>;
  scheduledCount?: Maybe<Scalars['Int']>;
  shortName?: Maybe<Scalars['String']>;
  staff?: Maybe<Array<Maybe<UnitStaff>>>;
  start?: Maybe<Scalars['TimeOnly']>;
};

export type UnitAppointmentType = {
  __typename?: 'UnitAppointmentType';
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  instructions?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  questions?: Maybe<Scalars['String']>;
};

export type UnitAppointmentTypeInput = {
  appointmentTypeId?: InputMaybe<Scalars['ID']>;
  unitId?: InputMaybe<Scalars['ID']>;
};

export type UnitInput = {
  ambulantTimeConfiguration?: InputMaybe<AmbulantTimeConfiguration>;
  appointmentTypes?: InputMaybe<Array<InputMaybe<UnitAppointmentTypeInput>>>;
  avatarId?: InputMaybe<Scalars['ID']>;
  companyId?: InputMaybe<Scalars['ID']>;
  contactPersonName?: InputMaybe<Scalars['String']>;
  contactPersonPosition?: InputMaybe<Scalars['String']>;
  coverId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  end?: InputMaybe<Scalars['TimeOnly']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  pauseEnd?: InputMaybe<Scalars['TimeOnly']>;
  pauseStart?: InputMaybe<Scalars['TimeOnly']>;
  shortName?: InputMaybe<Scalars['String']>;
  staff?: InputMaybe<Array<InputMaybe<UnitStaffInput>>>;
  start?: InputMaybe<Scalars['TimeOnly']>;
};

export type UnitScreen = {
  __typename?: 'UnitScreen';
  appointments?: Maybe<Array<Maybe<AppointmentScreen>>>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
};

export type UnitStaff = {
  __typename?: 'UnitStaff';
  avatarId?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  lastName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<UserType>;
  viber?: Maybe<Scalars['String']>;
};

export type UnitStaffInput = {
  unitId?: InputMaybe<Scalars['ID']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type User = {
  __typename?: 'User';
  authToken?: Maybe<Scalars['String']>;
  avatarId?: Maybe<Scalars['ID']>;
  canLogin?: Maybe<Scalars['Boolean']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['ID']>;
  created?: Maybe<Scalars['DateTime']>;
  deleted?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  emailConfirmed?: Maybe<Scalars['Boolean']>;
  facebook?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  initiatedCount?: Maybe<Scalars['Int']>;
  isLocked?: Maybe<Scalars['Boolean']>;
  isPhoneValid?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Language>;
  languageId?: Maybe<Scalars['Int']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  lastNotificationSeen?: Maybe<Scalars['DateTime']>;
  lockoutEnd?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  roleNames?: Maybe<Array<Maybe<RoleName>>>;
  scheduledCount?: Maybe<Scalars['Int']>;
  street?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<UserType>;
  viber?: Maybe<Scalars['String']>;
};

export type UserInput = {
  avatarId?: InputMaybe<Scalars['ID']>;
  companyId?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  iAgree?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  languageId?: InputMaybe<Scalars['ID']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  roleNames?: InputMaybe<Array<InputMaybe<RoleName>>>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<UserType>;
};

export enum UserType {
  Admin = 'ADMIN',
  Doctor = 'DOCTOR',
  Nurse = 'NURSE',
  Operator = 'OPERATOR',
  Operators = 'OPERATORS'
}
