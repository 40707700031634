import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent {

  @Input('chartData') chartData: any[] = [];
  @Input('printOnLoad') printOnLoad = true;
  @Input('showHeader') showHeader = true;
  title = '';
  date: any;


  constructor() {
    if (localStorage.getItem('dataToPrint')) {
      let dataToPrint = JSON.parse(localStorage.getItem('dataToPrint') || '');
      this.chartData = dataToPrint.data;
      this.title = dataToPrint.title;
      this.date = dataToPrint.dateRange;
      setTimeout(() => {
        localStorage.removeItem('dataToPrint');
        window.print();
        window.close();
      }, 900);
    }
  }

}
