import { NgModule } from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import {
  ApolloClientOptions,
  ApolloLink,
  InMemoryCache,
  split,
} from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { WebSocketLink } from '@apollo/client/link/ws';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { getMainDefinition } from '@apollo/client/utilities';

const uri = environment.apiUrl;
const wssUri = environment.socketUrl;

export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
  const middleware = new ApolloLink((operation, forward) => {
    let ls = localStorage.getItem('currentUser');
    let user;
    try {
      user = JSON.parse(ls || '{}');
    } catch (error) {
      localStorage.removeItem('currentUser');
      user = {};
    }

    if (user?.token) {
      operation.setContext({
        headers: new HttpHeaders().set(
          'Authorization',
          `Bearer ${user?.token}`
        ),
      });
    }
    return forward(operation);
  });

  const wsLink = new WebSocketLink(new SubscriptionClient(wssUri, {
    reconnect: true,
  }));

  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    middleware.concat(httpLink.create({ uri }))
  );

  return {
    link,
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            companies: {
              keyArgs: false,
              merge(existing = [], incoming) {
                return incoming;
              },
            },
            users: {
              keyArgs: false,
              merge(existing = [], incoming) {
                return incoming;
              },
            },
            countries: {
              keyArgs: false,
              merge(existing = [], incoming) {
                return incoming;
              },
            },
            cities: {
              keyArgs: false,
              merge(existing = [], incoming) {
                return incoming;
              },
            },
            translations: {
              keyArgs: false,
              merge(existing = [], incoming) {
                return incoming;
              },
            },
            ambulances: {
              keyArgs: false,
              merge(existing = [], incoming) {
                return incoming;
              },
            },
            patients: {
              keyArgs: false,
              merge(existing = [], incoming) {
                return incoming;
              },
            },
            appointments: {
              keyArgs: false,
              merge(existing = [], incoming) {
                return incoming;
              },
            },
            filteredAppointments: {
              keyArgs: false,
              merge(existing, incoming) {
                return incoming;
              },
            },
            PaginatorAppointmentGType: {
              keyArgs: false,
              merge(existing, incoming) {
                return incoming;
              },
            },
            appointmentTypes: {
              keyArgs: false,
              merge(existing = [], incoming) {
                return incoming;
              },
            },
            units: {
              keyArgs: false,
              merge(existing = [], incoming) {
                return incoming;
              },
            },
            scheduleTimeTables: {
              keyArgs: false,
              merge(existing = [], incoming) {
                return incoming;
              },
            },
            getFromUnit: {
              keyArgs: ['unitId', 'userType', 'companyId'],
              merge(existing = [], incoming) {
                return incoming;
              },
            },
            waitingListAppointments: {
              keyArgs: false,
              merge(existing = [], incoming) {
                return incoming;
              },
            },
            // newAppointmentEvent: {
            //   keyArgs: false,
            //   merge(existing = [], incoming) {
            //     console.log('write result to cache', existing, incoming);

            //     return incoming;
            //   },
            // },
          },
        },
        // Subscription: {
        //   fields: {
        //     newAppointmentEvent: {
        //       keyArgs: false,
        //       merge(existing = [], incoming) {
        //         console.log('write result to cache', existing, incoming);
        //         return incoming;
        //       },
        //     },
        //   },
        // },
      },
    }),
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule { }
