import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { Appointment, AppointmentStatus } from 'src/app/_graphql/schema';
import { AppointmentService } from 'src/app/pages/appointment/appointment.service';

@Component({
  selector: 'app-defer-appointment-action',
  templateUrl: './defer-appointment-action.component.html',
  styleUrls: ['./defer-appointment-action.component.scss']
})
export class DeferAppointmentActionComponent {

  @Input()
  id!: string;

  @Output()
  onAppointmentDefer = new EventEmitter<string>();

  appointmetnService = inject(AppointmentService);

  deferAppointment() {
    this.appointmetnService.deferAppointment(this.id).subscribe(() => {
      this.onAppointmentDefer.emit(this.id!);
      this.appointmetnService.refetchPolling({});
    });
  }
}
