import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Apollo, gql } from 'apollo-angular';
import { map, Subscribable } from 'rxjs';
import {
  Translation,
  TranslationInput,
  TranslationLanguage,
} from '../_graphql/schema';
import { AuthService } from '../_services/auth.service';
import { BaseService } from '../_services/base.service';

@Injectable({
  providedIn: 'root',
})
export class TranslationsService extends BaseService<TranslationLanguage> {
  loadedTranslations: boolean = false;
  currentLanguage!: string;

  override selectOneFields = gql`
    fragment SelectOneFields on Translation {
      id
      key
      value
      languageId
      language {
        id
        name
      }
      deleted
      created
      modified
    }
  `;

  override selectAllFields = gql`
    fragment SelectAllFields on TranslationLanguage {
      id
      key
      translations {
        languageId
        value
        language {
          id
          name
        }
      }
    }
  `;

  selectByLanguageQuery = gql`
    query translationsByLanguage($isoCode2: String) {
      translationsByLanguage(isoCode2: $isoCode2) {
        id
        key
        value
      }
    }
  `;

  selectByKey = gql`
    query translationsByKey($key: String!) {
      translationsByKey(key: $key) {
        id
        language {
          id
          name
        }
        languageId
        key
        value
      }
    }
  `;

  mutationCreateUpdateTranslation = gql`
    mutation createUpdateTranslations($items: [TranslationInput]!) {
      createUpdateTranslations(items: $items) {
        id
        languageId
        key
        value
      }
    }
  `;

  constructor(
    apollo: Apollo,
    public tt: TranslateService,
    public auth: AuthService
  ) {
    super(apollo);
    this.initGql('translation');
    this.queryParams.sortOrder = 'ASC';
    this.queryParams.sortBy = 'key';

    if (!this.loadedTranslations) {
      let lang = 'sr-lt';

      if (this.auth.user && this.auth.user.language?.isoCode2) {
        lang = this.auth.user.language.isoCode2;
      }

      this.tt.setDefaultLang(lang);
      this.tt.use(lang);
      this.getTranslations(lang);
      this.currentLanguage = lang;
    }
  }

  public getTranslationsByKey(key: string): Subscribable<Translation[]> {
    return this.query(this.selectByKey, { key }) as unknown as Subscribable<
      Translation[]
    >;
  }

  public createUpdateTranslation(
    items: TranslationInput[]
  ): Subscribable<Translation[]> {
    this.addRefetchQuery(this.selectByLanguageQuery, {
      isoCode2: this.currentLanguage,
    });
    this.addRefetchQuery(this.selectByKey, { key: items[0].key });
    this.addRefetchQuery(this.selectQuery, { queryParams: this.queryParams });

    return this.mutation(this.mutationCreateUpdateTranslation, { items });
  }

  public getTranslations(langCode = 'sr-lt') {
    var _trans;
    try {
      _trans = JSON.parse(
        localStorage.getItem(`__translations_${langCode}`) as string
      );
    } catch (error) {
      localStorage.removeItem(`__translations_${langCode}`);
    }
    if (_trans) {
      this.fillUpTranslations(langCode, _trans);
    }

    this.apollo
      .watchQuery({
        query: this.selectByLanguageQuery,
        variables: { isoCode2: langCode },
      })
      .valueChanges.pipe(
        map((result: any) => {
          if (!result.data || !result.data.translationsByLanguage) {
            return;
          }

          const translations: any = {};
          result.data.translationsByLanguage.map((tr: Translation) => {
            translations[tr.key] = tr.value;
          });

          localStorage.setItem(
            `__translations_${langCode}`,
            JSON.stringify(translations)
          );
          this.fillUpTranslations(langCode, translations);
        })
      )
      .subscribe((trans) => {});
  }

  public fillUpTranslations(langCode: string, translations: any) {
    this.tt.setTranslation(langCode, translations);

    this.tt.setDefaultLang(langCode);
    this.tt.use(langCode);
    this.loadedTranslations = true;
    this.currentLanguage = langCode;
  }
}
