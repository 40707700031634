import { inject, Injectable } from '@angular/core';
import { Apollo, gql, QueryRef } from 'apollo-angular';
import { catchError, first, map, of } from 'rxjs';
import { Appointment, AppointmentStatus } from 'src/app/_graphql/schema';
import { BaseService } from 'src/app/_services/base.service';
import { UiService } from 'src/app/_services/ui.service';

@Injectable({
  providedIn: 'root',
})
export class AppointmentService extends BaseService<Appointment> {
  readonly pollInterval: number = 5_000;
  private pollRef!: QueryRef<any>;

  protected override selectOneFields = gql`
    fragment SelectOneFields on Appointment {
      id
      patientId
      patient {
        id
        firstName
        lastName
        uIDN
        noShowUpCount
      }
      appointedTime
      patientNotes
      appointmentTypeId
      appointmentType {
        id
        name
        duration
      }
      scheduledTime
      scheduledTimeTableId
      isArranged
      isFirstTimeAppointment
      isControlAppointment
      controlAppointmentDays
      appointmentDue
      appointedToUnitId
      ambulantTime
      appointedToDoctorId
      status
      deleted
      arrangementNote
    }
  `;

  protected override selectAllFields = gql`
    fragment SelectAllFields on Appointment {
      id
      patient {
        id
        uIDN
        firstName
        lastName
        uIDN
        noShowUpCount
      }
      appointedTime
      status
      scheduledTime
      scheduledTimeTableId
      patientNotes
      appointmentType {
        id
        name
        duration
      }
      appointedToUnitId
      ambulantTime
      appointedToDoctorId
      isFirstTimeAppointment
      isControlAppointment
      appointedToUnit {
        id
        name
      }
      appointedToDoctor {
        id
        firstName
        lastName
      }
      isArranged
    }
  `;

  private filterAppointmentsQuery = gql`
    query filteredAppointments(
      $unitId: ID
      $doctorId: ID
      $appointmentStatus: AppointmentStatus
      $patientId: ID
      $startDate: DateOnly
      $endDate: DateOnly
      $pageRequestInput: PageRequestInput
    ) {
      filteredAppointments(
        unitId: $unitId
        doctorId: $doctorId
        appointmentSatus: $appointmentStatus
        patientId: $patientId
        startDate: $startDate
        endDate: $endDate
        pageRequestInput: $pageRequestInput
      ) {
        data {
          ...SelectAllFields
        }
        totalCount
      }
    }
    ${this.selectAllFields}
  `;

  private getAppointmentsForCalendarQuery = gql`
    query pollAppointments(
      $unitId: ID
      $doctorId: ID
      $appointmentStatus: AppointmentStatus
      $patientId: ID
      $startDate: DateOnly
      $endDate: DateOnly
      $pageRequestInput: PageRequestInput
    ) {
      filteredAppointments(
        unitId: $unitId
        doctorId: $doctorId
        appointmentSatus: $appointmentStatus
        patientId: $patientId
        startDate: $startDate
        endDate: $endDate
        pageRequestInput: $pageRequestInput
      ) {
        data {
          id
          patient {
            firstName
            lastName
            noShowUpCount
          }
          appointedTime
          scheduledTime
          status
          appointmentType {
            duration
          }
          isArranged
          patientNotes
        }
      }
    }
  `;

  ui = inject(UiService);
  constructor(apollo: Apollo) {
    super(apollo);
    this.initGql('appointment');
  }

  public filterAppointments(data: any) {
    return this.query(this.filterAppointmentsQuery, data);
  }

  public pollAppointmentsCalendarView(data: any) {
    if (!this.pollRef) {
      this.pollRef = this.apollo.watchQuery({
        query: this.getAppointmentsForCalendarQuery,
        fetchPolicy: 'network-only',
        variables: data,
        pollInterval: this.pollInterval,
      });
    }
    return this.pollRef.valueChanges.pipe(
      map((result: any) => {
        if (!result || !result.data) return null;

        const keys = Object.keys(result.data);
        if (result.data && keys.length) {
          return result.data[keys[0]];
        }
        return null;
      })
    );
  }

  public refetchPolling(variables: any) {
    this.stopPolling();
    this.pollRef?.refetch(variables);
    this.startPolling();
  }

  public stopPolling() {
    this.pollRef?.stopPolling();
  }

  public startPolling() {
    this.pollRef?.startPolling(this.pollInterval);
  }

  public setAppointmentStatus(id: string, status: AppointmentStatus) {
    return this.apollo
      .mutate({
        mutation: SetAppointmentStatusMutation,
        variables: { id, status },
      })
      .pipe(
        first(),
        catchError((err) => {
          this.ui.openSnackBarError(err.message);
          return of(null);
        })
      );
  }

  public setArrangedAppointment(id: string, note: string) {
    return this.apollo
      .mutate({
        mutation: SetArrangedAppointmentMutation,
        variables: { id, note },
      })
      .pipe(
        first(),
        catchError((err) => {
          this.ui.openSnackBarError(err.message);
          return of(null);
        })
      );
  }

  public deferAppointment(id: string) {
    return this.apollo.mutate({
      mutation: DefereAppointmentMutation,
      variables: { id }
    }).pipe(
      first(),
      map((e: any) => e.data.deferAppointment)
    )
  }
}

const SetAppointmentStatusMutation = gql`
  mutation SetAppointmentStatus($id: ID!, $status: AppointmentStatus!) {
    setAppointmentStatus(id: $id, status: $status) {
      id
      status
    }
  }
`;

const SetArrangedAppointmentMutation = gql`
  mutation SetArrangedAppointmentMutation($id: ID!, $note: String!) {
    setArrangedAppointment(id: $id, note: $note) {
      id
      patientId
      patientNotes
      status
      statusNote
      isArranged
      arrangementNote
    }
  }
`;

const DefereAppointmentMutation = gql`
mutation DefereAppointment($id:ID!){
  deferAppointment(id:$id){
    id
    patient {
      firstName
      lastName
      noShowUpCount
    }
    appointedTime
    scheduledTime
    status
    appointmentType {
      duration
    }
    isArranged
    patientNotes
  }
}`;
