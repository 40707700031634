import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material.module';
import { WrappersModule } from '../wrappers/wrappers.module';
import { PasswordStrengthComponent } from './password-strength/password-strength.component';
import { IconComponent } from './icon/icon.component';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { NoteDialogComponent } from './note-dialog/note-dialog.component';
import { SetArrangedAppointmentsComponent } from './set-arranged-appointments/set-arranged-appointments.component';
import { DeferAppointmentActionComponent } from './defer-appointment-action/defer-appointment-action.component';

@NgModule({
  declarations: [
    PasswordStrengthComponent,
    IconComponent,
    ConfirmationDialogComponent,
    TimePickerComponent,
    NoteDialogComponent,
    SetArrangedAppointmentsComponent,
    DeferAppointmentActionComponent,
  ],
  imports: [CommonModule, MaterialModule, WrappersModule, TranslateModule],
  exports: [
    PasswordStrengthComponent,
    IconComponent,
    SetArrangedAppointmentsComponent,
    DeferAppointmentActionComponent
  ],
})
export class ComponentsModule { }
