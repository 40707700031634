<app-public-wrapper>
   <div fxLayout="column" fxLayoutAlign="center center">
      <form [formGroup]="form" fxFlex="50" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="8px"
         (submit)="verify()">
         <h3>{{'verify_user_title' | translate}}</h3>
         <p>{{'verify_user_description' | translate}}</p>
         <mat-form-field fxFlex>
            <mat-label>{{ 'Email'|translate}}</mat-label>
            <input matInput type="email" formControlName="email" />
         </mat-form-field>
         <mat-form-field>
            <mat-label>{{'VerificationCode'|translate}}</mat-label>
            <input matInput formControlName="token" minlength="4" />
         </mat-form-field>
         <mat-form-field>
            <mat-label>{{'Password'|translate}}</mat-label>
            <input matInput type="password" formControlName="password" minlength="4" [type]="hide ? 'password' : 'text'"
               (keyup.enter)="verify()" />
            <button mat-icon-button matSuffix type="button" [attr.aria-label]="'Hide password'"
               [attr.aria-pressed]="hide">
               <mat-icon (click)="hide = !hide">{{ hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
         </mat-form-field>
         <button mat-raised-button fxFlex color="primary" [disabled]="form.invalid">{{'Save'|translate}}</button>
      </form>
   </div>
</app-public-wrapper>