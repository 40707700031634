import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs';
import { UserService } from 'src/app/registers/user/user.service';
import { UiService } from 'src/app/_services/ui.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  hide: boolean = false;
  repeatPassword: FormControl<string | null> = new FormControl<string | null>('', Validators.required);

  form: FormGroup = this.fb.group({
    oldPassword: [null, Validators.required],
    password: [null, Validators.required]
  })

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private router: Router,
    private ui: UiService,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    console.log('data', data);

  }

  ngOnInit(): void {

  }

  changePassword() {
    this.userService.changePassword(this.form.getRawValue()).pipe(first()).subscribe({
      next: () => {
        this.form.enable();
        this.dialogRef.close();
        this.ui.openSnackBar("PasswordSuccessfullyReseted");
      },
      error: err => {
        this.form.enable();
        this.ui.openSnackBarError(err);
      }
    });
  }

}
