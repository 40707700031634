import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Unsubscribable } from 'rxjs';
import { AuthService } from 'src/app/_services/auth.service';
import { UiService } from 'src/app/_services/ui.service';

@Component({
  selector: 'item-wrapper',
  templateUrl: './item-wrapper.component.html',
  styleUrls: ['./item-wrapper.component.scss']
})
export class ItemWrapperComponent implements OnInit, OnDestroy {

  dataId!: string;
  data$!: Unsubscribable;
  route$!: Unsubscribable;
  @Input('form') form!: FormGroup;
  @Input('readPerm') readPerm!: boolean;
  @Input('managePerm') managePerm!: boolean;
  @Input('normalizeProps') normalizeProps: string[] = ['tags'];
  @Input('isDeleted') isDeleted = false;
  @Input('deleteEnabled') deleteEnabled = false;
  @Input('title') title!: string;
  @Input('mainService') mainService: any;
  @Input('navigateList') navigateList!: string[];
  @Input('backOnSave') backOnSave = true;
  @Input('backOnCreate') backOnCreate = true;
  @Input('ignoreServiceLoader') ignoreServiceLoader = false;
  @Input('disableServiceLogic') disableServiceLogic = false;

  @Output() afterOneItem: EventEmitter<any> = new EventEmitter<any>();
  @Output() afterUpdateItem: EventEmitter<any> = new EventEmitter<any>();
  @Output() afterCreateItem: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public auth: AuthService,
    public ui: UiService,
    public router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) { }


  ngOnInit(): void {
    this.route$ = this.route.paramMap.subscribe((x: any) => {
      if (x?.params?.id != 'new')
        this.dataId = x.params.id;
      else if (this.mainService)
        this.mainService.loading = false;

      this.ui.setTitle(this.title);

      if (this.managePerm) {
        this.form.enable();
      } else {
        this.form.disable();
      }

      if (this.dataId && this.mainService) {
        this.ui.loaderShow();
        this.data$ =
          this.mainService.one(this.dataId).subscribe({
            next: (data: any) => {
              if (data && data.hasOwnProperty('deleted') && this.managePerm) {
                this.deleteEnabled = true;
                if (data?.deleted != null) {
                  this.isDeleted = true;
                  this.form.disable();
                }

              }
              if (this.form) {
                this.form.patchValue(data);
              }
              this.afterOneItem.emit(data);
              this.ui.loaderStop();
            }, error: (error: any) => {
              this.ui.openSnackBarError(error.message);
            }
          });
      }
      else
        this.afterOneItem.emit('new');

    });
  }

  submit() {
    if (this.disableServiceLogic)
      return;

    //this if handle only patients form
    if (this.form.value.hasOwnProperty('uIDN') && !this.form.value.uIDN && !this.form.value.documentId) {
      this.ui.openSnackBarWarning(this.translate.instant('PleaseFillUpJMBGorDocumentField'));
      return;
    }

    var data = Object.assign({}, this.form.value);
    this.ui.loaderShow();
    this.normalizeProps?.forEach(elem => {
      if (data[elem])
        data[elem] = this.normalizeTags(data[elem]);
    });

    if (data['id']) {
      this.mainService.modify({ item: data }).subscribe(
        (retval: any) => {
          this.ui.loaderStop();
          this.ui.openSnackBar("Changes saved", "Ok");
          this.form.patchValue(retval);
          if (this.backOnSave)
            this.getBack();
          this.afterUpdateItem.emit(retval);
        },
        (error: any) => {
          this.ui.loaderStop();
          this.ui.openSnackBarError(error.message);
        }
      );
    } else {
      var create$ = this.mainService.create({ item: data }).subscribe(
        (retval: any) => {
          this.ui.loaderStop();
          this.ui.openSnackBar("Item created");
          if (this.form) {
            this.form.patchValue(retval);
          }
          this.afterCreateItem.emit(retval);
          if (this.backOnCreate)
            this.getBack();
          // create$.unsubscribe();
        },
        (error: any) => {
          this.ui.loaderStop();
          this.ui.openSnackBarError(error.message);
          // create$.unsubscribe();
        }
      );
    }
  }

  del() {
    if (!this.dataId)
      return;

    if (this.dataId === this.auth.user.id) {
      this.ui.openSnackBarWarning(this.translate.instant('YouCannotDeleteYourself'));
      return;
    }

    var delete$ = this.mainService.delete({ id: this.dataId }).subscribe(
      ({ retval }: any) => {
        this.ui.openSnackBar(this.translate.instant("Item deleted"));
        this.getBack();
        // delete$.unsubscribe();
      },
      (error: any) => {
        this.ui.loaderStop();
        this.ui.openSnackBarError(error.message);
        // delete$.unsubscribe();

      }
    );
  }

  restore() {
    if (!this.dataId)
      return;

    this.mainService.restore({ id: this.dataId }).subscribe(
      ({ retval }: any) => {
        this.ui.openSnackBar(this.translate.instant("Item restored"));
        this.getBack();
      },
      (error: any) => {
        this.ui.loaderStop();
        this.ui.openSnackBarError(error.message);
      }
    );
  }

  getBack() {
    this.router.navigate(['..', ...this.navigateList]);
  }

  normalizeTags(tags: any) { // tags: Tag[]
    if (!tags || tags.length == 0)
      return tags;

    return tags.map((tag: any) => {
      return { id: tag.id };
    });
  }

  ngOnDestroy() {
    this.data$?.unsubscribe();
    this.route$?.unsubscribe();
  }

}
