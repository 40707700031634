<mat-sidenav-container [hasBackdrop]="menuBackdrop" fxFill autosize>
  <mat-sidenav
    #sidenav
    [mode]="menuMode"
    [ngClass]="{ minimized: minimize }"
    [opened]="menuOpen"
    (click)="menuItemSelected()"
  >
    <div fxFill fxLayout="column">
      <img
        src="../../../assets/images/logo_red.svg"
        class="logo"
        *ngIf="!minimize"
      />
      <img
        src="../../../assets/images/logo_red.svg"
        class="logo-mini"
        *ngIf="minimize"
      />
      <mat-divider></mat-divider>
      <div style="position: relative; flex: 1">
        <div [ngClass]="{ 'first-level': true, open: !expanded }">
          <mat-divider></mat-divider>

          <button
            *ngIf="auth.hasPermission('appointment', 'overview')"
            mat-menu-item
            routerLink="/pages/appointments"
            routerLinkActive="active"
          >
            <mat-icon>event</mat-icon>
            <span *ngIf="!minimize">{{ "Appointments" | translate }}</span>
          </button>

          <button
            *ngIf="auth.hasPermission('ScheduledTimeTable', 'overview')"
            mat-menu-item
            routerLink="/pages/schedule-time-tables"
            routerLinkActive="active"
          >
            <mat-icon>table_chart</mat-icon>
            <span *ngIf="!minimize">{{ "ScheduleTimeTable" | translate }}</span>
          </button>

          <button
            *ngIf="auth.hasPermission('appointment', 'overview')"
            mat-menu-item
            routerLink="/pages/scheduled-appointments"
            routerLinkActive="active"
          >
            <mat-icon>event_available</mat-icon>
            <span *ngIf="!minimize">{{ "AllAppointments" | translate }}</span>
          </button>

          <button
            *ngIf="auth.hasPermission('waitinglist', 'overview')"
            mat-menu-item
            routerLink="/pages/waiting-list"
            routerLinkActive="active"
          >
            <mat-icon>list</mat-icon>
            <span *ngIf="!minimize">{{ "WaitingList" | translate }}</span>
          </button>

          <button
            *ngIf="auth.hasPermission('unitStaff', 'overview')"
            mat-menu-item
            routerLink="/pages/unit-staff"
            routerLinkActive="active"
          >
            <mat-icon>local_hospital</mat-icon>
            <span *ngIf="!minimize">{{ "UnitStaff" | translate }}</span>
          </button>

          <!-- TODO !!! change translation permission with waitinRoon permission -->
          <button
            *ngIf="auth.hasPermission('translation', 'overview')"
            mat-menu-item
            routerLink="/pages/configure-waiting-room"
            routerLinkActive="active"
          >
            <mat-icon>event_seat</mat-icon>
            <span *ngIf="!minimize">{{
              "Konfiguracija čekaonica" | translate
            }}</span>
          </button>

          <button
            *ngIf="auth.hasPermission('statistics', 'overview')"
            mat-menu-item
            routerLink="/pages/statistics"
            routerLinkActive="active"
          >
            <mat-icon>show_chart</mat-icon>
            <span *ngIf="!minimize">{{ "Statistics" | translate }}</span>
          </button>
        </div>
        <div [ngClass]="{ 'second-level': true, open: expanded }">
          <button mat-menu-item (click)="setSubMenuOpen('null')">
            <mat-icon>arrow_back_ios</mat-icon>
            <span *ngIf="!minimize">{{ "Back" | translate }}</span>
          </button>
          <div *ngIf="openSubmenu == 'secondLayer'">
            <!-- add more options here -->
          </div>
          <div *ngIf="openSubmenu == 'settings'" class="items">
            <button
              *ngIf="auth.hasPermission('translation', 'overview')"
              mat-menu-item
              routerLink="/registers/translations"
              routerLinkActive="active"
            >
              <mat-icon>translate</mat-icon>
              <span *ngIf="!minimize">{{ "Translations" | translate }}</span>
            </button>
            <button
              *ngIf="auth.hasPermission('country', 'overview')"
              mat-menu-item
              routerLink="/registers/countries"
              routerLinkActive="active"
            >
              <mat-icon>languages</mat-icon>
              <span *ngIf="!minimize">{{ "Countries" | translate }}</span>
            </button>
            <button
              *ngIf="auth.hasPermission('city', 'overview')"
              mat-menu-item
              routerLink="/registers/cities"
              routerLinkActive="active"
            >
              <mat-icon>location_on</mat-icon>
              <span *ngIf="!minimize">{{ "Cities" | translate }}</span>
            </button>
            <button
              *ngIf="auth.hasPermission('patient', 'overview')"
              mat-menu-item
              routerLink="/pages/patients"
              routerLinkActive="active"
            >
              <mat-icon>people_outline</mat-icon>
              <span *ngIf="!minimize">{{ "Patients" | translate }}</span>
            </button>
            <button
              *ngIf="auth.hasPermission('company', 'overview')"
              mat-menu-item
              routerLink="/registers/companies"
              routerLinkActive="active"
            >
              <mat-icon>business_center</mat-icon>
              <span *ngIf="!minimize">{{ "Companies" | translate }}</span>
            </button>
            <button
              *ngIf="auth.hasPermission('user', 'overview')"
              mat-menu-item
              routerLink="/registers/users"
              routerLinkActive="active"
            >
              <mat-icon>people</mat-icon>
              <span *ngIf="!minimize">{{ "Users" | translate }}</span>
            </button>
            <button
              *ngIf="auth.hasPermission('appointmentType', 'overview')"
              mat-menu-item
              routerLink="/registers/appointment-types"
              routerLinkActive="active"
            >
              <mat-icon>event</mat-icon>
              <span *ngIf="!minimize">{{
                "AppointmentTypes" | translate
              }}</span>
            </button>
            <button
              *ngIf="auth.hasPermission('unit', 'overview')"
              mat-menu-item
              routerLink="/pages/units"
              routerLinkActive="active"
            >
              <mat-icon>local_hospital</mat-icon>
              <span *ngIf="!minimize">{{ "Units" | translate }}</span>
            </button>
          </div>
        </div>
      </div>

      <div style="margin-top: auto"></div>

      <button mat-menu-item (click)="minimize = !minimize" *ngIf="minimize">
        <mat-icon *ngIf="!minimize">chevron_left</mat-icon>
        <mat-icon *ngIf="minimize">chevron_right</mat-icon>
        <span *ngIf="!minimize">Show as Rail</span>
      </button>
      <button
        mat-menu-item
        (click)="setSubMenuOpen('settings'); stayWhereYouAre = true"
      >
        <mat-icon>settings</mat-icon>
        <span *ngIf="!minimize">{{ "Settings" | translate }}</span>
        <button
          *ngIf="!minimize"
          mat-icon-button
          class="minimise-btn"
          (click)="onMinimise($event)"
        >
          <mat-icon *ngIf="!minimize">chevron_left</mat-icon>
        </button>
      </button>
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <mat-progress-bar *ngIf="loading" mode="query"></mat-progress-bar>
    <mat-toolbar>
      <mat-toolbar-row>
        <button
          *ngIf="!ui.menuHasBack"
          mat-icon-button
          (click)="sidenav.toggle()"
        >
          <mat-icon>menu</mat-icon>
        </button>
        <button
          *ngIf="ui.menuHasBack"
          mat-icon-button
          (click)="sidenav.toggle()"
        >
          <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <span class="page-title">{{ title }}</span>
        <span fxFlex></span>
        {{ auth.user?.company?.name | translate }} ({{
          auth.user?.company?.shortName | translate
        }})
        <span fxFlex></span>
        <app-user-menu></app-user-menu>
      </mat-toolbar-row>
    </mat-toolbar>
    <div class="sidebar">
      <router-outlet name="sidebar"></router-outlet>
    </div>
    <!-- <router-outlet name="companyDetails"></router-outlet> -->
    <!-- <div class="toolbox">{{ ui.title() }}</div> -->
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>

<mat-menu #menu="matMenu"></mat-menu>
