import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Appointment } from '../_graphql/schema';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  private static activeSidebar: boolean = false;
  public $activeSidebar!: Subject<boolean>;

  private static scheduleRange: { start: string; end: string };
  public $scheduleRange!: Subject<{ start: string; end: string }>;

  private static eventUpdateId: {
    appointment: Appointment;
    schedule: boolean;
  } = { appointment: {}, schedule: false };
  public $rescheduleAppointments!: Subject<{
    appointment: Appointment;
    schedule: boolean;
    deleted?: boolean;
  }>;

  private static refetchAfterSwitch: boolean = false;
  public $refetchAfterSwitch!: Subject<boolean>;

  public set activeSideBar(data: boolean) {
    HelperService.activeSidebar = data;
    this.$activeSidebar.next(HelperService.activeSidebar);
  }

  public get activeSideBar() {
    return HelperService.activeSidebar;
  }

  public set scheduleRange(data: { start: string; end: string }) {
    HelperService.scheduleRange = data;
    this.$scheduleRange.next(HelperService.scheduleRange);
  }

  public set eventUpdateId(data: {
    appointment: Appointment;
    schedule: boolean;
  }) {
    HelperService.eventUpdateId = data;
    this.$rescheduleAppointments.next(data);
  }

  public set refetchAfterSwitch(refetch: boolean) {
    HelperService.refetchAfterSwitch = refetch;
    this.$refetchAfterSwitch.next(refetch);
  }

  constructor() {
    this.$activeSidebar = new Subject();
    this.$activeSidebar.next(HelperService.activeSidebar);

    this.$scheduleRange = new Subject();
    this.$scheduleRange.next(HelperService.scheduleRange);

    this.$rescheduleAppointments = new Subject();
    this.$rescheduleAppointments.next(HelperService.eventUpdateId);

    this.$refetchAfterSwitch = new Subject();
    this.$refetchAfterSwitch.next(HelperService.refetchAfterSwitch);
  }
}
