import { AfterContentChecked, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { fromEvent, Observable, Subscription, Unsubscribable } from 'rxjs';
import { AuthService } from 'src/app/_services/auth.service';
import { HelperService } from 'src/app/_services/helper.service';
import { UiService } from 'src/app/_services/ui.service';

@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss']
})
export class WrapperComponent implements OnInit, OnDestroy, AfterContentChecked {

  title!: string;

  resizeObservable$!: Observable<Event>;
  resizeSubscription$!: Subscription;
  openSubmenu = "";
  expanded: boolean = false;
  menuMode: any = 'over';
  menuOpen = false;
  menuBackdrop = true;
  minimize = false;
  loading = false;
  loading$!: Subscription;
  appWidth!: number;
  stayWhereYouAre: boolean = false;

  @ViewChild('sidenav', { static: true }) sidenav!: MatSidenav;

  company: any;
  unsub$!: Unsubscribable;

  constructor(
    public ui: UiService,
    public router: Router,
    public route: ActivatedRoute,
    private helper: HelperService,
    public auth: AuthService,
  ) { }

  ngAfterContentChecked(): void {
    this.title = this.ui.title;
    this.loading$ = this.ui.loaders.subscribe(visible => {
      this.loading = visible;
    });
  }

  ngOnInit(): void {
    this.unsub$ = this.helper.$activeSidebar.subscribe({
      next: res => {
        this.minimize = res;
      }
    });

    this.ui.menuSideNav = this.sidenav;
    this.resizeObservable$ = fromEvent(window, 'resize')
    this.resizeSubscription$ = this.resizeObservable$.subscribe(evt => {
      this.onResize();
    })
    this.onResize();
  }

  setMenu(sidenav: MatSidenav) {
    this.menuBackdrop = !this.menuOpen;
    if (this.menuOpen) {
      this.menuMode = 'side';
      this.sidenav.open();
    } else {
      this.menuMode = 'over';
      this.sidenav.close();
    }
  }

  onMinimise(event: any) {
    this.minimize = !this.minimize;
    event.stopPropagation();
  }

  menuItemSelected() {
    if (this.menuMode == 'over' && this.stayWhereYouAre === false)
      this.sidenav.close();
    else {
      this.sidenav.open();
      this.stayWhereYouAre = false;
    }
  }

  onResize() {
    if (this.appWidth == window.innerWidth)
      return;
    if (window.innerWidth < 960) {
      this.menuMode = 'over';
      this.menuBackdrop = true;
      this.menuOpen = false;
    } else {
      this.menuMode = 'side';
      this.menuBackdrop = false;
      this.menuOpen = true;
    }
    this.appWidth = window.innerWidth;
  }
  setSubMenuOpen(openSubmenu: string = 'null') {
    this.stayWhereYouAre = false;
    this.openSubmenu = openSubmenu;

    this.expanded = (openSubmenu != 'null');
  }

  ngOnDestroy() {
    this.loading$?.unsubscribe();
    this.resizeSubscription$?.unsubscribe();
    this.unsub$?.unsubscribe();
  }
}
