import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extractProperty'
})
export class ExtractPropertyPipe implements PipeTransform {

  transform(obj: any, property: string): any {
    return property.split('.').reduce((o: any, i: string) => { if (o && o[i] != null && o[i] != undefined) return o[i]; return "-"; }, obj);
  }
}