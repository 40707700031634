import { Component, Input, OnInit } from '@angular/core';
import { AppointmentStatus } from 'src/app/_graphql/schema';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {

  @Input('status') status!: any;

  constructor() { }

  ngOnInit(): void {
  }

}
