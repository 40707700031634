import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WrappersModule } from '../wrappers/wrappers.module';
import { MaterialModule } from '../material.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    WrappersModule,
    MaterialModule,
    TranslateModule.forRoot(),
  ],
})
export class PagesModule {}
