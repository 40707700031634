<mat-icon
  *ngIf="status == 'INITIATED'"
  class="warn"
  [matTooltip]="'INITIATED' | translate"
>
  update
</mat-icon>
<mat-icon
  *ngIf="status == 'SCHEDULED'"
  class="warn"
  [matTooltip]="'SCHEDULED' | translate"
>
  schedule</mat-icon
>
<mat-icon
  *ngIf="status == 'CONFIRMED'"
  class="warn"
  [matTooltip]="'CONFIRMED' | translate"
>
  check_circle_outline
</mat-icon>
<mat-icon
  *ngIf="status == 'VISITED'"
  color="accent"
  [matTooltip]="'VISITED' | translate"
>
  check_circle_outline</mat-icon
>
<mat-icon
  *ngIf="status == 'NO_SHOW_UP'"
  color="primary"
  [matTooltip]="'NO_SHOW_UP' | translate"
>
  not_interested</mat-icon
>
<mat-icon
  *ngIf="status == 'CANCELED_BY_PATIENT'"
  color="primary"
  [matTooltip]="'CANCELED_BY_PATIENT' | translate"
>
  highlight_off</mat-icon
>
<mat-icon
  *ngIf="status == 'CANCELED_BY_OPERATOR'"
  color="primary"
  [matTooltip]="'CANCELED_BY_OPERATOR' | translate"
>
  remove_circle_outline</mat-icon
>
<mat-icon
  *ngIf="status == 'CANCELED_BY_DOCTOR'"
  color="primary"
  [matTooltip]="'CANCELED_BY_DOCTOR' | translate"
>
  error_outline</mat-icon
>

<mat-icon
  *ngIf="status == 'ARRIVED'"
  class="warn"
  [matTooltip]="'ARRIVED' | translate"
>
  check_circle</mat-icon
>

<mat-icon
  *ngIf="status == 'IN_PROGRESS'"
  class="warn"
  [matTooltip]="'IN_PROGRESS' | translate"
>
  history</mat-icon
>
