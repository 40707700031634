import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { ReportComponent } from './pages/statistics/report/report.component';
import { ChangePasswordComponent } from './register/change-password/change-password.component';
import { ForgotPasswordComponent } from './register/forgot-password/forgot-password.component';
import { LoginComponent } from './register/login/login.component';
import { ResetPasswordComponent } from './register/reset-password/reset-password.component';
import { VerifyComponent } from './register/verify/verify.component';
import { WrapperComponent } from './wrappers/wrapper/wrapper.component';
import { AccessPermissionsGuard } from './_guards/access-permissions.guard';
import { LoginGuard } from './_guards/login.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
  },
  {
    path: 'reset-password/:username/:verificationCode',
    component: ResetPasswordComponent,
  },
  {
    path: 'verify/:email/:verificationCode',
    component: VerifyComponent,
  },
  {
    path: 'statistics/print',
    canActivate: [LoginGuard, AccessPermissionsGuard],
    component: ReportComponent,
    data: { service: 'statistics', permission: 'overview' },
  },
  {
    path: 'waiting-room/:viewDuration/:numOfPatients/:closeDialogAfter/:unitIds',
    // TODO !!! WE NEED TO HANDLE PERMISSIONS
    loadComponent: () =>
      import(
        './pages/waitin-room/waiting-room-screen/waiting-room-screen.component'
      ).then((c) => c.WaitingRoomScreenComponent),
  },
  {
    path: '',
    component: WrapperComponent,
    canActivate: [LoginGuard],
    children: [
      {
        path: 'registers',
        loadChildren: () =>
          import('./registers/registers-routing.module').then(
            (m) => m.RegistersRoutingModule
          ),
      },
      {
        path: 'pages',
        loadChildren: () =>
          import('./pages/pages-routing.module').then(
            (m) => m.PagesRoutingModule
          ),
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'login',
    pathMatch: 'full',
  },
];
const options: ExtraOptions = {
  paramsInheritanceStrategy: 'always',
};
@NgModule({
  imports: [RouterModule.forRoot(routes, options)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
