import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';
import { ChangePasswordComponent } from '../register/change-password/change-password.component';

const snackDuration: number = 10_000;

@Injectable({
  providedIn: 'root'
})

export class UiService {
  public snacks!: Subject<string>;
  public loaders!: Subject<boolean>;
  public loading = false;
  public menuSideNav!: MatSidenav;
  public menuHasBack = false;

  private _appTitle = "";

  constructor(
    private appTitle: Title,
    private _snackBar: MatSnackBar,
    public translate: TranslateService,
    private dialog: MatDialog,
  ) {
    this.snacks = new Subject<string>();
    this.loaders = new Subject<boolean>();
  }

  public openSnackBar(message: string, action: string = "Ok") {
    this._snackBar.open(this.translate.instant(message), action, { duration: snackDuration });
  }

  public openSnackBarWarning(message: string, action: string = "Ok") {
    this._snackBar.open(this.translate.instant(message), action, { duration: snackDuration, panelClass: ['warning-snackbar'] });
  }

  public openSnackBarError(message: string, action: string = "Ok") {
    this._snackBar.open(message, action, { duration: snackDuration, panelClass: ['error-snackbar'] });
  }

  public menuToggle() {
    this.menuSideNav.toggle();
  }

  public setTitle(title: string) {
    if (title) {
      this.translate.get(title).subscribe(title => {
        requestAnimationFrame(() => {
          this._appTitle = title;
          this.appTitle.setTitle(title);
        })
      })
    }
  }

  public get title() {
    return this._appTitle;
  }

  loaderShow() {
    requestAnimationFrame(() => {
      this.loaders.next(true);
      this.loading = true;
    });
  }

  loaderStop() {
    requestAnimationFrame(() => {
      this.loading = false;
      this.loaders.next(false);
    });
  }

  openSchedulerDialog(): MatDialogRef<any, any> {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: '450px',
    });
    return dialogRef;
  }

  openConfirmationDialog(infoMessage: string = 'DoYouWantToDelete', title: string = 'Delete', approveAction: string = 'Delete', denyAction: string = 'No'): MatDialogRef<any, any> {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: { infoMessage, title, approveAction, denyAction },
    });
    return dialogRef;
  }
}
