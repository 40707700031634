import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Subscribable } from 'rxjs';
import { Company } from 'src/app/_graphql/schema';
import { BaseService } from 'src/app/_services/base.service';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService extends BaseService<Company> {

  protected override selectOneFields = gql`
  fragment SelectOneFields on Company {
    id name shortName description status directorName directorPhone regNumber taxNumber
    idNumber statusUserNote adminNote countryId avatarId
    users{ id }
  }
`;

  protected override selectAllFields = gql`
  fragment SelectAllFields on Company{
    id name shortName status directorName
  }
  `;

  switchCompanyMutation = gql`
  mutation switchCompany ($companyId: ID!) {
    switchCompany (companyId: $companyId) {
      id
      title
      firstName
      lastName
      type
      isLocked
      companyId
      company {
        id
        name
        shortName
        status
      }
      authToken
      emailConfirmed
      permissions
      roleNames
    }
  }
  `

  constructor(apollo: Apollo) {
    super(apollo);
    this.initGql("company", "companies");
    // this.queryParams.sortBy = "name";
    // this.queryParams.sortOrder = "asc";
  }

  public switchCompany(companyId: string): Subscribable<any> {
    localStorage.setItem('companyId', companyId);

    return super
      .mutation(this.switchCompanyMutation, { companyId: companyId });
  }
}
