import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { catchError, first, map, of } from 'rxjs';
import { Unit } from 'src/app/_graphql/schema';
import { BaseService } from 'src/app/_services/base.service';

@Injectable({
  providedIn: 'root',
})
export class UnitService extends BaseService<Unit> {
  readonly pollInterval: number = 10000;
  refetchCount: any;

  protected override selectOneFields = gql`
    fragment SelectOneFields on Unit {
      id
      name
      shortName
      description
      contactPersonName
      contactPersonPosition
      start
      end
      company {
        id
        name
      }
      companyId
      created
      modified
      deleted
      appointmentTypes {
        id
        name
        description
        instructions
        questions
        duration
      }
      staff {
        id
        firstName
        lastName
        email
        type
      }
      avatarId
      coverId
      ambulantTimeConfiguration
      pauseStart
      pauseEnd
      created
      modified
      deleted
    }
  `;

  protected override selectAllFields = gql`
    fragment SelectAllFields on Unit {
      id
      name
      ambulantTimeConfiguration
      start
      end
      initiatedCount
      scheduledCount
      # staff {  id firstName lastName email type}
      # appointmentTypes {id name }
      # appointmentTypes { id name description instructions questions duration }
      description
    }
  `;

  private companyUnitsQuery = gql`
    query companyUnits($companyId: ID!, $userId: ID) {
      companyUnits(companyId: $companyId, userId: $userId) {
        ...SelectAllFields
      }
    }
    ${this.selectAllFields}
  `;

  private unitCountGql = gql`
    query onWaitinList($id: ID) {
      unit(id: $id) {
        initiatedCount
        scheduledCount
      }
    }
  `;

  constructor(apollo: Apollo) {
    super(apollo);
    this.initGql('unit');
    this.queryParams.sortBy = 'name';
    this.queryParams.sortOrder = 'asc';
  }

  public filterUnits(data: any) {
    return this.query(this.companyUnitsQuery, data);
  }

  public onWaitingList(data: any) {
    if (!this.refetchCount) {
      this.refetchCount = this.apollo.watchQuery({
        query: this.unitCountGql,
        fetchPolicy: 'network-only',
        variables: data,
        pollInterval: this.pollInterval,
      });
    }
    return this.refetchCount.valueChanges.pipe(
      map((result: any) => {
        if (!result || !result.data) return null;

        const keys = Object.keys(result.data);
        if (result.data && keys.length) {
          return result.data[keys[0]];
        }

        return null;
      })
    );
  }

  public refetchCOuntOnWaitingList(data: any) {
    this.refetchCount?.refetch(data);
  }
}
