import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {
  @Input('title') title = 'Delete';
  @Input('confirmationText') confirmationText = 'DoYouWantToDelete';
  @Input('confirmText') confirmText = 'Yes';
  @Input('deniedText') deniedText = 'No';

  constructor(public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,) {
    this.confirmationText = data?.infoMessage;
    this.title = data?.title;
    this.confirmText = data?.approveAction;
    this.deniedText = data?.denyAction
  }
}
