import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { first, Unsubscribable } from 'rxjs';
import { UserService } from 'src/app/registers/user/user.service';
import { UiService } from 'src/app/_services/ui.service';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnDestroy {

  hide: boolean = false;
  route$!: Unsubscribable;

  repeatPassword: FormControl<string | null> = new FormControl<string | null>('', Validators.required);

  form: FormGroup = this.fb.group({
    username: [null, [Validators.required]],
    verificationCode: [null, [Validators.required]],
    newPassword: [null, [Validators.required]],
  });

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private router: Router,
    private ui: UiService,
    private translate: TranslateService,
    private route: ActivatedRoute,
  ) {
    this.form.get('verificationCode')?.disable();
    this.form.get('username')?.disable();

    route.params.subscribe(res => {
      this.form.patchValue({
        username: res['username'],
        verificationCode: res['verificationCode'],
      });
    })
  }

  resetPassword() {
    if (this.form.value.newPassword !== this.repeatPassword.value) {
      this.ui.openSnackBarWarning("PasswordsDoesNotMatch");
      return;
    }
    this.form.disable();
    this.userService.resetPasswordUser(this.form.value).pipe(first()).subscribe({
      next: () => {
        this.ui.openSnackBar(this.translate.instant('SuccesfullyChangedPAssword'));
        this.router.navigate(['/']);
      },
      error: err => this.ui.openSnackBar(this.translate.instant(err.message))
    })
  }

  ngOnDestroy(): void {
    this.route$?.unsubscribe();
  }
}