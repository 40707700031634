<div class="item-container" *ngIf="!mainService?.loading || ignoreServiceLoader || !dataId" title="">
   <div fxLayout="row" class="toolbox">
      <button mat-icon-button (click)="getBack()">
         <mat-icon>arrow_back</mat-icon>
      </button>
      <div class="item-title">
         <div class="title">
            {{ form.value?.name || form.value?.title || '' }}
            {{ form.value?.firstName || form.value?.firstName || '' }} {{form.value?.lastName ||
            form.value?.lastName || '' }}
         </div>
         <div class="subtitle"> {{ ((form.value?.id ? 'Edit' : 'New')+title) |translate }}</div>
      </div>
      <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="deleteEnabled  && auth.hasPermission('User','Manage')">
         <mat-icon>more_vert</mat-icon>
      </button>
      <ng-content select="[saveBtn]"></ng-content>
      <div *ngIf="managePerm && !isDeleted && !disableServiceLogic">
         <button (click)="submit()" mat-flat-button color="secondary" [disabled]="!form.dirty || form.invalid"
            *ngIf="!form.dirty || form.invalid; else submitfilled">{{'Save' | translate}}</button>

         <ng-template #submitfilled><button mat-flat-button (click)="submit()" color="primary">{{'Save' |
               translate}}</button>
         </ng-template>
      </div>
      <mat-menu #menu="matMenu">
         <button mat-menu-item color="warn" (click)="del()" *ngIf="!isDeleted"
            [disabled]="!dataId">{{'Delete'|translate}}</button>
         <button mat-menu-item color="warn" (click)="restore()" *ngIf="isDeleted" [disabled]="!dataId">{{'Restore' |
            translate}}</button>
      </mat-menu>
   </div>
   <div class="spacer">
      <div *ngIf="readPerm==null" class="infoblock warning"><strong>readPerm</strong> is not defined on Item Wrapper
      </div>
      <div *ngIf="managePerm==null" class="infoblock warning"><strong>managePerm</strong> is not defined on Item
         Wrapper
      </div>
      <ng-content select="[toolbox]"></ng-content>
   </div>
   <div class="holder">
      <ng-content></ng-content>
   </div>
</div>