<app-public-wrapper>
   <div>
      <form [formGroup]="form" fxFlex="50" fxLayout="column" fxLayoutAlign="center" fxLayoutGap="8px"
         (submit)="requestResetPassword()">
         <h3>{{'forgot_password_title' | translate}}</h3>
         <p>{{'forgot_password_description' | translate}}</p>
         <mat-form-field fxFlex>
            <mat-label>{{ 'Username'|translate}}</mat-label>
            <input matInput type="email" formControlName="username" />
         </mat-form-field>
         <button mat-raised-button fxFlex color="primary"
            [disabled]="!form.valid">{{'ResetPassword'|translate}}</button>
      </form>
   </div>
</app-public-wrapper>