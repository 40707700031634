<div class="table-content">
   <div *ngIf="showHeader && title != ''" fxLayout="row" fxLayoutAlign="space-between center" class="mb-4">
      <div class="text-center">
         <img src="../../../assets/images/logo.png" class="logo">
      </div>
      <h2>{{title}}</h2>
      <div fxLayout="column" class="small-text">
         <label><strong>{{"ManagementUKCRS"|translate}}</strong></label>
         <label><strong>{{'Date'|translate}}:</strong> {{date?.start |date:'dd.MM.yyyy'}} - {{date?.end
            |date:'dd.MM.yyyy'}}</label>
         <label><strong>{{'Fax'|translate}}: </strong>{{'ContactNmber'|translate}}</label>
      </div>
   </div>
   <table>
      <tr>
         <th>{{'Name'|translate}}</th>
         <th>{{'Count'|translate}}</th>
      </tr>
      <tr *ngIf="!chartData?.length">
         <td>{{"ListIsEmpty" | translate}}</td>
         <td>{{""}}</td>
      </tr>
      <tr *ngFor="let item of chartData">
         <td>{{item.name | translate}}</td>
         <td>{{item.value}}</td>
      </tr>
   </table>
</div>