import { DialogRef } from '@angular/cdk/dialog';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppointmentService } from 'src/app/pages/appointment/appointment.service';
import { NoteDialogComponent } from '../note-dialog/note-dialog.component';
import { first, of, switchMap } from 'rxjs';

@Component({
  selector: 'app-set-arranged-appointments',
  templateUrl: './set-arranged-appointments.component.html',
  styleUrls: ['./set-arranged-appointments.component.scss'],
})
export class SetArrangedAppointmentsComponent {
  @Input()
  id!: string;

  dialogRef!: DialogRef<NoteDialogComponent, any>;

  constructor(private service: AppointmentService, private dialog: MatDialog) {}

  setAppointmentAsArranged() {
    const dialogRef = this.dialog.open(NoteDialogComponent, {
      width: '450px',
    });

    dialogRef
      .afterClosed()
      .pipe(
        first(),
        switchMap((e) =>
          !!e ? this.service.setArrangedAppointment(this.id, e) : of(null)
        )
      )
      .subscribe();
  }
}
