<app-public-wrapper>
   <div>
      <form [formGroup]="form" fxFlex="50" fxLayout="column" fxLayoutAlign="center" fxLayoutGap="8px"
         (submit)="login()">
         <mat-form-field fxFlex>
            <mat-label>{{ 'Username'|translate}}</mat-label>
            <input matInput type="email" formControlName="username" />
         </mat-form-field>
         <mat-form-field>
            <mat-label>{{'Password'|translate}}</mat-label>
            <input matInput type="password" formControlName="password" minlength="4" [type]="hide ? 'password' : 'text'"
               (keyup.enter)="login()" />
            <button mat-icon-button matSuffix type="button" [attr.aria-label]="'Hide password'"
               [attr.aria-pressed]="hide">
               <mat-icon (click)="hide = !hide">{{ hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
         </mat-form-field>

         <button mat-raised-button fxFlex color="primary"
            [disabled]="!form.valid || disableLoginBtn">{{'Login'|translate}}</button>
         <div fxLayout="column" fxLayoutAlign="space-around center">
            <a class="fancy-link section-subtitle" type="button" href="/forgot-password"
               routerLink="/forgot-password">{{'ForgotPassword?'| translate}}
            </a>
         </div>
      </form>
   </div>
</app-public-wrapper>