import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/registers/user/user.service';
import { UiService } from 'src/app/_services/ui.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {

  form: FormGroup = this.fb.group({
    username: [null, [Validators.required, Validators.email]]
  })

  constructor(private fb: FormBuilder,
    private userService: UserService,
    private ui: UiService,
    private router: Router
  ) { }

  requestResetPassword() {
    this.form.disable();
    let k = this.userService.requestResetPasswordUser(this.form.value).subscribe({
      next: () => {
        this.form.enable();
        this.router.navigate(['login']);
        this.ui.openSnackBar("ResetPasswordSuccessfullyRequestedPleaseCheckYourEmail");
        k?.unsubscribe();
      },
      error: err => {
        this.ui.openSnackBarError(err);
        this.form.enable();
        k?.unsubscribe();
      }
    })
  }

}
