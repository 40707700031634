<div class="toolbox" fxLayoutGap="12px">
   <mat-form-field class="search">
      <mat-label>{{'Search'|translate}}</mat-label>
      <input matInput [formControl]="search">
   </mat-form-field>
   <div fxFlex></div>
   <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="showDeleted">
      <mat-icon>more_vert</mat-icon>
   </button>
   <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="getDeleted()">
         <mat-icon *ngIf="!_showDeleted">check_box_outline_blank</mat-icon>
         <mat-icon *ngIf="_showDeleted">check_box</mat-icon>
         {{'Show deleted items'|translate}}
      </button>
   </mat-menu>
</div>

<div style="padding: 16px 24px;">

   <mat-action-list *ngIf="!disableList" class="mat-elevation-z8">
      <mat-list-item class="list-header">
         <div *ngFor="let column of headers">{{column|translate | uppercase}}</div>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item *ngFor="let item of $data|async" class="list-item" (click)="edit(item.id)">
         <div *ngFor="let property of properties">
            <div mat-line [ngClass]="(property == 'uIDN' && item.noShowUpCount > 2)?'redText':''"
               [matTooltip]="(property == 'uIDN' && item.noShowUpCount > 2)?('NoShowUpMoreThan2Times'|translate):(item | extractProperty:property)">
               {{ item | extractProperty:property}} </div>
         </div>
         <mat-divider></mat-divider>
      </mat-list-item>
      <mat-list-item *ngIf="($data|async)?.length==0" disabled="true">
         <div>
            <div mat-line>{{ 'ListIsEmpty' | translate}}</div>
         </div>
      </mat-list-item>
   </mat-action-list>

   <ng-content *ngIf="disableList"></ng-content>

   <ng-content select="[footer]"></ng-content>
   <mat-paginator *ngIf="showPagination" [length]="mainService.totalCount" [pageSize]="mainService.queryParams.take"
      [pageSizeOptions]="[5, 10, 25, 100]" (page)="mainService.applyPager($event.pageIndex, $event.pageSize)"
      [pageIndex]="mainService.pageIndex">
   </mat-paginator>
</div>

<button *ngIf="managePerm && !hideAddNew" color="primary" class="fab-fixed" mat-fab aria-label="Add new"
   (click)="newItem()">
   <mat-icon>add</mat-icon>
</button>