<div class="header">
  <h1 mat-dialog-title>
    {{ "Označi pregled kao privilegovan" | translate }}
  </h1>
  <button mat-icon-button [mat-dialog-close]="null">
    <mat-icon>clear</mat-icon>
  </button>
</div>
<div mat-dialog-content>
  <mat-form-field>
    <mat-label>{{ "Razlog privilegije" | translate }}</mat-label>
    <input matInput [formControl]="noteControl" />
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <div style="flex: 1"></div>
  <button mat-button [mat-dialog-close]="null">
    {{ "Otkaži" | translate }}
  </button>
  <button
    mat-button
    color="primary"
    [disabled]="!noteControl.value"
    [mat-dialog-close]="noteControl.value"
    cdkFocusInitial
  >
    {{ "Potvrdi" | translate }}
  </button>
</div>
