import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicWrapperComponent } from './public-wrapper/public-wrapper.component';
import { MaterialModule } from '../material.module';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { WrapperComponent } from './wrapper/wrapper.component';
import { UserMenuComponent } from './public-wrapper/user-menu/user-menu.component';
import { SidebarWrapperComponent } from './sidebar-wrapper/sidebar-wrapper.component';
import { ListWrapperComponent } from './list-wrapper/list-wrapper.component';
import { ItemWrapperComponent } from './item-wrapper/item-wrapper.component';
import { PipesModule } from '../_pipes/pipes.module';


@NgModule({
  declarations: [
    PublicWrapperComponent,
    WrapperComponent,
    SidebarWrapperComponent,
    UserMenuComponent,
    ListWrapperComponent,
    ItemWrapperComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    TranslateModule,
    PipesModule
  ],
  exports: [
    PublicWrapperComponent,
    SidebarWrapperComponent,
    ListWrapperComponent,
    ItemWrapperComponent,
    WrapperComponent
  ]
})
export class WrappersModule { }
