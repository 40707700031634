import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from 'src/app/material.module';
import { WrappersModule } from 'src/app/wrappers/wrappers.module';
import { PipesModule } from 'src/app/_pipes/pipes.module';


@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    MaterialModule,
    WrappersModule,
    PipesModule
  ],
  exports: []
})
export class AmbulancesModule { }
