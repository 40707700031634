import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperService } from 'src/app/_services/helper.service';


@Component({
  selector: 'app-sidebar-wrapper',
  templateUrl: './sidebar-wrapper.component.html',
  styleUrls: ['./sidebar-wrapper.component.scss'],

  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateX(100%)' }))
      ])
    ])
  ]
})
export class SidebarWrapperComponent implements OnInit {

  @Input('color') color: string = '#393C46';

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private helper: HelperService,
  ) { }

  ngOnInit(): void {

  }

  closeSidebar() {
    this.helper.activeSideBar = false;
    this.router.navigate([{ outlets: { sidebar: null } }],
      { relativeTo: this.route.parent?.parent });
  }

}
