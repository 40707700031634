import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslationsService } from './translations/translations.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'UKC_Front';
  constructor(public trans: TranslationsService, private tt: TranslateService) { }
}
