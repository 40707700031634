import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { AuthService } from '../_services/auth.service';
import { UiService } from '../_services/ui.service';

@Injectable({
  providedIn: 'root'
})
export class AccessPermissionsGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private ui: UiService,
    private translateService: TranslateService,
    private router: Router
  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let permission = this.auth.hasPermission(route?.data['service'], route?.data['permission']);
    if (!permission) {
      this.ui.openSnackBarWarning(this.translateService.instant("AccessDenied"));
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }

}
